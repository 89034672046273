import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import { MemberDetails } from '../../../interfaces/member.interface';
import { AppConstants, OrderStatus } from '../../../app.constants';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EligibilityService } from '../../../core/services/eligibility/eligibility.service';
import { BenefitManagementService } from '../../../core/services/benefit-management/benefit-management.service';
import { AppInsightsService } from '../../../services/app-insights/app-insights.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { AppCenterAnalytics } from '../../../services/app-insights/app-insights';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PrescriptionDetailsDialogComponent } from './prescription-details-dialog/prescription-details-dialog.component';
import { PRESCRIPTION_STATUS } from 'src/app/interfaces/enum';
import { formatDate } from 'src/app/util';
import { SharedService } from 'src/app/services/shared/shared.service';
import { LoaderService } from '../../services/loader/loader.service';
import { Prescription, PrescriptionsResponse } from 'src/app/interfaces/prescription.interface';

@Component({
  selector: 'app-prescriptions',
  templateUrl: './prescriptions.component.html',
  styleUrls: ['./prescriptions.component.scss']
})
export class PrescriptionsComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public displayedColumns: string[] = ['prescribedDrugName', 'rxNumber', 'patientFirstName', 'prescriberFirstName', 'quantity', 'daysSupply', 'nextRefillDate', 'fillableStatus', 'autoRefill'];
  public dataSource: MatTableDataSource<Prescription> = new MatTableDataSource([] as Prescription[]);
  public selectedMemberDetails$: Observable<Partial<MemberDetails>>;
  public memberDetails: MemberDetails;
  public migratedFlag: string;
  public displayCoverageType = AppConstants.DISPLAY_COVERAGE_TYPE;
  public prescriptionsSearchFormGroup: FormGroup;
  public relationShip = AppConstants.RELATIONSHIP_VALUES;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  public prescriptionsResponse!: PrescriptionsResponse;
  public isLoading: boolean;
  public maxDate = new Date();
  public searchList = '';
  public status = OrderStatus.All;;
  public isTableDataLoaded: boolean = false;
  public totalLength!: number;
  public drugClassValues = [2, 3, 4, 5];
  public clientIdsWithoutAutoChargeAndShip: string[] = [];
  public statusList = [
    PRESCRIPTION_STATUS.IN_PROGRESS,
    PRESCRIPTION_STATUS.TOO_SOON,
    PRESCRIPTION_STATUS.REFILL,
    PRESCRIPTION_STATUS.REQUEST_RENEW_PRESCRIPTION,
    PRESCRIPTION_STATUS.AUTO_REFILL,
    PRESCRIPTION_STATUS.TRANSFERRED,
    PRESCRIPTION_STATUS.INACTIVE,
    PRESCRIPTION_STATUS.NO_MORE_REFILLS,
  ]
  public userAccessLevel!: string;
  constructor(
    private readonly router: Router,
    public eligibilityService: EligibilityService,
    private activatedRoute: ActivatedRoute,
    private benefitService: BenefitManagementService,
    private readonly appInsightService: AppInsightsService,
    private formBuilder: FormBuilder,
    private readonly dialog: MatDialog,
    private readonly sharedService: SharedService,
    private readonly loader: LoaderService,
  ) { }

  ngOnInit(): void {
    this.subscription.add(this.sharedService.getClientWithAutoChargeAndShip().subscribe((res) => {
      this.clientIdsWithoutAutoChargeAndShip = res[0].split(',').map((id) => id.trim());
    }
    ));
    this.subscription.add(this.sharedService.accessLevel$.subscribe((v) => {
      this.userAccessLevel = v;
    }));
    this.selectedMemberDetails$ = this.activatedRoute.queryParams.pipe(
      switchMap((res) => {
        return this.eligibilityService.getMemberDetailsById(res).pipe(
          map((res) => {
            this.memberDetails = res[0];
            return res[0];
          }),
          tap(() => this.checkGroupLevelAccess()),
          tap(() => this.loadPrescriptions()),
        );
      })
    );
  }

  private loadPrescriptions() {
    this.appInsightService.trackPageView(AppCenterAnalytics.PRESCRIPTIONS);
    const requiredValidator = Validators.required;
    this.prescriptionsSearchFormGroup = this.formBuilder.group(
      {
        formDateControl: ['',
          requiredValidator,
        ],
        toDateControl: ['',
          requiredValidator,
        ],
      },
      { validators: this.dateRangeValidator }
    );
  }

  getPrescription(): void {
    this.isLoading = true;
    this.loader.showLoader();
    let reqObj = {
      clientId: this.memberDetails.clientId,
      cardId: this.memberDetails.cardId,
      personCode: this.memberDetails.personCode,
      startDate: formatDate(
        new Date(this.prescriptionsSearchFormGroup.value.formDateControl)
      ),
      endDate: formatDate(
        new Date(this.prescriptionsSearchFormGroup.value.toDateControl)
      ),
      migrationFlag: this.migratedFlag,
      accessLevel: this.userAccessLevel
    }
    this.appInsightService.trackPageView(AppCenterAnalytics.PRESCRIPTIONS_API_REQUEST_PAYLOAD, { data: reqObj });
    this.subscription.add(this.eligibilityService.getPrescription(reqObj).subscribe((prescriptionsResponse) => {
      this.isTableDataLoaded = true;
      this.isLoading = false;
      this.appInsightService.trackPageView(AppCenterAnalytics.PRESCRIPTIONS_API_SUCCESS);
      this.loader.hideLoader();
      if (prescriptionsResponse) {
        this.prescriptionsResponse = prescriptionsResponse;
        let displayData = this.prescriptionsResponse.prescriptions = this.prescriptionsResponse.prescriptions
          .sort((a, b) => new Date(b.dispensedDate).valueOf() - (new Date(a.dispensedDate).valueOf()));
        this.dataSource = new MatTableDataSource(this.filterByStatus(displayData));
        this.totalLength = this.dataSource.data.length;
        this.loadPagination();
      }
    }, (err) => {
      this.appInsightService.trackPageView(AppCenterAnalytics.PRESCRIPTIONS_API_ERROR, { Response: err });
      this.loader.hideLoader();
    }))
  }
  private loadPagination(): void {
    this.dataSource.filterPredicate = (data: any, filter) => {
      const dataStr = JSON.stringify(data).toLowerCase();
      return dataStr.indexOf(filter) !== -1;
    };
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }, 0)
  }
  public filterByStatus(prescription: any): any {
    return prescription.filter((o) => {
      return [
        PRESCRIPTION_STATUS.IN_PROGRESS,
        PRESCRIPTION_STATUS.TOO_SOON,
        PRESCRIPTION_STATUS.REFILL,
        PRESCRIPTION_STATUS.REQUEST_RENEW_PRESCRIPTION,
        PRESCRIPTION_STATUS.AUTO_REFILL,
        PRESCRIPTION_STATUS.TRANSFERRED,
        PRESCRIPTION_STATUS.INACTIVE,
        PRESCRIPTION_STATUS.NO_MORE_REFILLS,
      ].includes(o.fillableStatus);
    })
  }
  dateRangeValidator(control: AbstractControl): ValidationErrors | null {
    const fromDate = control.get('formDateControl').value;
    const toDate = control.get('toDateControl').value;

    if (fromDate && toDate && fromDate > toDate) {
      control.get('formDateControl').setErrors({ dateInvalid: true });
      control.get('toDateControl').setErrors({ dateInvalid: true });
      return { dateInvalid: true };
    }
  }

  private checkGroupLevelAccess() {
    this.subscription.add(this.benefitService.getClientsListByAccess().pipe(
      tap((clients) => {
        const selectedClient = clients.find(c => c.clientId === this.memberDetails.clientId);
        this.migratedFlag = selectedClient?.migrationFlag ? selectedClient?.migrationFlag : 'N';
        this.eligibilityService.updateIsMigratedValue(selectedClient.migrationFlag && selectedClient.migrationFlag === 'Y')
        this.eligibilityService.updateIsGroupLevelAccessValue(selectedClient?.groupLevelFlag);
      })
    ).subscribe())
  }

  public dateDifferenceValidator(): null | { dateDifference: true } {
    const startDate = this.prescriptionsSearchFormGroup.value.formDateControl;
    const endDate = this.prescriptionsSearchFormGroup.value.toDateControl;

    if (startDate && endDate) {
      const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
      const differenceInYears =
        differenceInMilliseconds / (1000 * 60 * 60 * 24 * 365);

      if (differenceInYears > 2) {
        return { dateDifference: true };
      }
    }
    return null;
  }

  public navigateBackToMemberSearch(): void {
    this.router.navigateByUrl('/management/online-eligibility');
  }

  public search(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.totalLength = this.dataSource.filteredData.length;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  public statusChange(): void {
    this.searchList = '';
    if (this.status == 'all') {
      let displayData2 = this.prescriptionsResponse.prescriptions;
      this.dataSource = new MatTableDataSource(this.filterByStatus(displayData2));
      this.totalLength = this.dataSource.data.length;
    } else {
      let displayData2 = this.prescriptionsResponse.prescriptions.filter((o) => o.fillableStatus === this.status);
      this.dataSource = new MatTableDataSource(displayData2);
      this.totalLength = displayData2.length;
    }
    this.loadPagination();
  }

  public openDialog(prescription: Prescription): void {
    const dialogRef: MatDialogRef<PrescriptionDetailsDialogComponent> =
      this.dialog.open(PrescriptionDetailsDialogComponent, {
        autoFocus: false,
        maxWidth: window.innerWidth < 766 ? 350 : 760,
        minWidth: window.innerWidth < 766 ? 'auto' : 700,
        maxHeight: 550,
        panelClass: 'mail-order-modalbox',
        data: { prescriptions: prescription, memberDetails: this.memberDetails, clientIdsWithoutAutoChargeAndShip: this.clientIdsWithoutAutoChargeAndShip }
      });
  }

  public resetSearch(): void {
    this.isTableDataLoaded = false;
    this.isLoading = true;
    this.status = OrderStatus.All;
    this.searchList = '';
    this.getPrescription();
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
