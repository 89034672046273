/**
 * @file auth.guard.ts
 */
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared/shared.service';
import { UserAccess, UserAccessLevel } from 'src/app/interfaces/user-access.interface';
import { AppConstants } from 'src/app/app.constants';
import { ErrorManagerService, ErrorObject } from '../../services/error';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';
import { AppCenterAnalytics } from 'src/app/services/app-insights/app-insights';

@Injectable({
  providedIn: 'root'
})
export class UserAccessGuard implements CanActivate {

  constructor(
    private readonly router: Router,
    private readonly sharedService: SharedService,
    private readonly errorManager: ErrorManagerService,
    private readonly appInsightsService: AppInsightsService
  ) { }

  /**
   * Checks if the user has access to reporting portal or Management Portal or both.
   * @param route 
   * @param state 
   * @returns 
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.getAccess().pipe(map((res) => {
      this.appInsightsService.trackPageView(AppCenterAnalytics.GET_USER_ACCESS_TYPE_API_SUCCESS,{Response: res});
      if (!res.isOLAUser && !res.isReportsUser) {
        // if user doesn't have access to any of the module, show error message
        const error: ErrorObject = { message: AppConstants.ERROR.MESSAGES.NO_ACCESS };
        this.errorManager.throwError(error);
        return false;
      }

      sessionStorage.setItem(AppConstants.LABELS.userName, res.userName);

      localStorage.setItem(AppConstants.LABELS.hasReportsAccess, res.isReportsUser ? 'YES' : 'NO');
      localStorage.setItem(AppConstants.LABELS.hasManagementAccess, res.isOLAUser ? 'YES' : 'NO');

      if (res.isOLAUser) {
        this.sharedService.updateAccessLevel(res.olaUserType);
      }

      if (res.isOLAUser && res.isReportsUser) {
        // if user has both access, redirect to module selection screen. either reporting or management.
        return true;
      } else {
        // if user has access only to reporting module, redirect to client selection page
        if (res.isReportsUser) {
          this.router.navigateByUrl('/main');
        }

        // if user has access only to management module, redirect to management dashboard
        if (res.isOLAUser) {
          return true;
        }
        return false;
      }
    },(error)=>{
      this.appInsightsService.trackPageView(AppCenterAnalytics.GET_USER_ACCESS_TYPE_API_ERROR,{Response: error});
    }));
  }

  public getAccess(): Observable<UserAccess> {
    return this.sharedService.getUserAccessRoles();
  }
}

