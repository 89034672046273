import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AuditLogsService } from 'src/app/core/services/audit-logs/audit-logs.service';
import { BenefitManagementService } from 'src/app/core/services/benefit-management/benefit-management.service';
import {
  AuditLogsInterface,
  AuditLogsRequestInterface,
  AuditLogsResponseInterface,
  SearchLogsInterface,
} from 'src/app/interfaces/audit-logs.interface';
import { Clients } from 'src/app/interfaces/clients.interface';

import { EligibilityMenu } from 'src/app/interfaces/menu.interface';
import { AppCenterAnalytics } from 'src/app/services/app-insights/app-insights';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';
import { LoaderService } from '../services/loader/loader.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { formatDate, formatDateToMMDDYYYY } from 'src/app/util';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { DemoClientService } from 'src/app/core/services/demo-client/demo-client.service';
import { SharedService } from '../../services/shared/shared.service';

@Component({
  selector: 'app-audit-logs',
  templateUrl: './audit-logs.component.html',
  styleUrls: ['./audit-logs.component.scss'],
})
export class AuditLogsComponent implements OnInit {
  public memberMenus: EligibilityMenu[] = [];
  public checkTouchForForm = false;
  public dataSource: MatTableDataSource<AuditLogsInterface[]> =
    new MatTableDataSource([]);
  public searchAuditLogsFormGroup: FormGroup;
  public auditLogs$!: Observable<AuditLogsInterface[]>;
  public listOfClients$: Observable<Array<Clients>>;
  public hasAccessToAddMember: boolean;
  public maxDate = new Date();
  public isInternalUser: boolean;
  selection = new SelectionModel<any>(true, []);

  allFieldsEmpty: boolean = true;
  selectedClientId: string = '';
  dataLoaded: boolean = false;

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private readonly formBuilder: FormBuilder,
    public auditLogsService: AuditLogsService,
    private benefitService: BenefitManagementService,
    private readonly appInsightService: AppInsightsService,
    private loader: LoaderService,
    private authService: AuthService,
    private demoClientService: DemoClientService,
    private sharedService: SharedService
  ) { }

  public displayedColumns: string[] = [
    'select',
    'lastName',
    'firstName',
    'cardId',
    'birthDt',
    'personCode',
    'groupNumAndName',
    'dateAdded',
    'change',
    'changedBy',
    'comments',
    'errorCode',
    'errorMessage',
    'isSuccess'
  ];

  ngOnInit(): void {
    this.appInsightService.trackPageView(AppCenterAnalytics.AUDIT_LOGS);
    this.benefitService.isInternalUser().pipe(
      tap((internal) => {
        this.isInternalUser = internal;
        if (!internal) {
          this.displayedColumns = this.displayedColumns.filter(
            (type) => type !== 'errorCode' && type !== 'errorMessage' && type !== 'isSuccess'
          );
        }
      })
    ).subscribe();
    this.searchAuditLogsFormGroup = this.formBuilder.group(
      {
        client: ['', [Validators.required]],
        cardId: ['', [Validators.pattern(/^[a-zA-Z0-9]{1,20}$/)]],
        firstName: ['', [Validators.maxLength(35)]],
        lastName: ['', [Validators.maxLength(35)]],
        from: ['', [Validators.required]],
        to: ['', [Validators.required]],
      },
      { validators: this.dateRangeValidator }
    );

    this.searchAuditLogsFormGroup
      .get('from')
      .valueChanges.subscribe((startDate) => {
        // Update the end date filter when the start date changes
        this.searchAuditLogsFormGroup.get('to').updateValueAndValidity();
      });

    this.searchAuditLogsFormGroup.patchValue(
      this.auditLogsService.searchCriteriaCache
    );

    this.listOfClients$ = this.benefitService.getClientsListByAccess();

    this.searchAuditLogsFormGroup.valueChanges.subscribe((formValue) => {
      const formControls = Object.keys(formValue);
      // console.log(this.searchAuditLogsFormGroup);
      const areAllFieldsEmpty = formControls.every((controlName) => {
        const control = this.searchAuditLogsFormGroup.get(controlName);
        return (
          control.value === null ||
          control.value === undefined ||
          control.value === ''
        );
      });

      if (areAllFieldsEmpty) {
        this.allFieldsEmpty = true;
        // console.log('All fields are empty');
      } else {
        this.allFieldsEmpty = false;
        // console.log('All fields are not empty');
      }
    });
  }

  markFieldAsTouched() {
    this.searchAuditLogsFormGroup.get('cardId').markAsTouched();
  }

  isDemoClient(clientId: string) {
    this.loader.showLoader();
    this.demoClientService.checkDemoClient(clientId).subscribe({
      next: (res) => {
        // console.log('Response on isDemoClient:', res);
        this.demoClientService.updateDemoClientFlag = res;
      },
      error: (err) => {
        this.loader.hideLoader();
      },
      complete: () => {
        this.loader.hideLoader();
      },
    });
  }

  getAuditLogs() {
    return this.auditLogsService.searchAuditLogs();
  }

  endDateFilter = (date: Date | null): boolean => {
    const startDate = this.searchAuditLogsFormGroup.get('from').value as Date;

    // Disable dates before the selected start date
    return date >= startDate;
  };

  dateRangeValidator(control: AbstractControl): ValidationErrors | null {
    const fromDate = control.get('from').value;
    const toDate = control.get('to').value;

    if (fromDate && toDate && fromDate > toDate) {
      control.get('from').setErrors({ dateInvalid: true });
      control.get('to').setErrors({ dateInvalid: true });
      return { dateInvalid: true };
    }

    // Clear errors if validation passes
    // control.get('from').setErrors(null);
    // control.get('to').setErrors(null);
    return null;
  }

  checkDateValues() {
    // console
    //   .log
    // 'From Value, before:',
    // this.searchAuditLogsFormGroup.controls.from.value
    // 'after:',
    // new Date(this.searchAuditLogsFormGroup.controls.from.value).toISOString()
    // ();
    // console
    //   .log
    // 'To Value, before:',
    // this.searchAuditLogsFormGroup.controls.to.value
    // 'after:',
    // new Date(this.searchAuditLogsFormGroup.controls.to.value).toISOString()
    // ();
  }

  compareDates(a: string, b: string, isAsc: boolean): number {
    const dateA = new Date(a);
    const dateB = new Date(b);

    return (dateA < dateB ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public onClientSelection(event: MatSelectChange) {
    this.sharedService.updateAccessLevel(event.value.olA_Access_Type);
    // console.log(event.value);
    if (this.dataLoaded) {
      this.searchAuditLogsFormGroup.get('firstName').patchValue('');
      this.searchAuditLogsFormGroup.get('lastName').patchValue('');
      this.searchAuditLogsFormGroup.get('cardId').patchValue('');
      this.searchAuditLogsFormGroup.get('from').reset();
      this.searchAuditLogsFormGroup.get('to').reset();
      this.dataLoaded = false;
    }
    this.selectedClientId = event.value.clientId;
    this.isDemoClient(event.value.clientId);
  }

  formatDate(date: string): string {
    return formatDateToMMDDYYYY(date);
  }

  public searchAuditLogs() {
    this.loader.showLoader();

    this.selection = new SelectionModel<any>(true, []);
    const fromDate = this.searchAuditLogsFormGroup.controls.from.value;
    const toDate = this.searchAuditLogsFormGroup.controls.to.value;

    // Convert local date to UTC date
    let fromUTCDate = fromDate
      ? `${formatDate(new Date(fromDate))}T00:00:00.000Z`
      : null;

    let toUTCDate = toDate
      ? `${formatDate(new Date(toDate))}T23:59:59.999Z`
      : null;

    fromUTCDate = new Date(
      new Date(fromUTCDate).getTime() + new Date().getTimezoneOffset() * 60000
    ).toISOString();
    toUTCDate = new Date(
      new Date(toUTCDate).getTime() + new Date().getTimezoneOffset() * 60000
    ).toISOString();

    let reqBody: AuditLogsRequestInterface = {
      // emailId: this.authService.userEmail,
      clientId: this.selectedClientId,
      firstName: this.searchAuditLogsFormGroup.controls.firstName.value.trim(),
      lastName: this.searchAuditLogsFormGroup.controls.lastName.value.trim(),
      cardId: this.searchAuditLogsFormGroup.controls.cardId.value,
      fromDate: fromUTCDate,
      toDate: toUTCDate,
      userType: this.isInternalUser ? 'internal' : 'external',
    };
    this.appInsightService.trackPageView(AppCenterAnalytics.GET_AUDIT_LOG_REQUEST ,{data:reqBody});
    this.auditLogsService.inputSearchCriteria.next(reqBody);

    this.auditLogs$ = this.getAuditLogs().pipe(
      tap((res) => {
        // console.log('Response:', res);
      }),
      map((res) => {
        // console.log('Res:', res);
        if (res) {
          // this.dataSource = new MatTableDataSource(res);
          this.dataSource = new MatTableDataSource(res);
          // console.log('DataSource:', this.dataSource);
          setTimeout(() => {
            this.dataSource.paginator = this.paginator;
            // this.sort.sort({
            //   id: 'birthDt',
            //   start: 'desc',
            // } as MatSortable);
            this.dataSource.sort = this.sort;

            this.dataSource.sortingDataAccessor = (
              data: any,
              sortHeaderId: string
            ): string => {
              if (typeof data[sortHeaderId] === 'string') {
                return data[sortHeaderId].toLocaleLowerCase();
              }
            };
          }, 0);
          this.loader.hideLoader();
        }
        this.dataLoaded = true;
        return res;
      }),
      catchError((err) => {
        // console.log('Error:', err);
        this.dataLoaded = true;
        this.loader.hideLoader();
        return of([]);
      })
    );
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  parseComments(element: any): string {
    if (element.changes.includes('Requested Duplicate ID card')) {
      if (
        element.comments &&
        typeof element.comments === 'string' &&
        element.comments.trim() !== ''
      ) {
        return JSON.parse(element.comments).comment;
      } else {
        return '';
      }
    } else {
      return element.comments;
    }
  }

  printSelectedRows() {
    const selectedRows = this.selection.selected;
    // console.log('Selected rows: ', selectedRows);
    if (selectedRows.length === 0) return;
    const printFrame = document.getElementById(
      'printFrame'
    ) as HTMLIFrameElement;
    const printDoc =
      printFrame.contentDocument || printFrame.contentWindow.document;

    let content = '<html><head><title>Print Selected Rows</title>';
    // content += '<link rel="stylesheet" href="path_to_your_custom_styles.css">';
    content += '</head><body>';
    content +=
      '<table style="border: 1px solid #000; border-collapse: collapse; font-family: arial;">';

    content += '<thead>';
    content += '<tr>';
    content +=
      '<th style="border: 1px solid #000; border-collapse: collapse; padding: 2px">Last Name</th>';
    content +=
      '<th style="border: 1px solid #000; border-collapse: collapse; padding: 2px">First Name</th>';
    content +=
      '<th style="border: 1px solid #000; border-collapse: collapse; padding: 2px">DOB</th>';
    content +=
      '<th style="border: 1px solid #000; border-collapse: collapse; padding: 2px">Person Code</th>';
    content +=
      '<th style="border: 1px solid #000; border-collapse: collapse; padding: 2px; width: 150px;">Group# and Name</th>';
    content +=
      '<th style="border: 1px solid #000; border-collapse: collapse; padding: 2px">Entry Date</th>';
    content +=
      '<th style="border: 1px solid #000; border-collapse: collapse; padding: 2px">Change</th>';
    content +=
      '<th style="border: 1px solid #000; border-collapse: collapse; padding: 2px">Comments</th>';
    if (this.isInternalUser) {
      content +=
        '<th style="border: 1px solid #000; border-collapse: collapse; padding: 2px">Error Code</th>';
      content +=
        '<th style="border: 1px solid #000; border-collapse: collapse; padding: 2px">Error Message</th>';
      content +=
        '<th style="border: 1px solid #000; border-collapse: collapse; padding: 2px">Status</th>';
    }
    content += '</tr>';
    content += '</thead>';

    content += '<tbody>';
    for (let row of selectedRows) {
      content += '<tr>';
      content +=
        '<td style="border: 1px solid #000; border-collapse: collapse; padding: 2px">' +
        row.lastName +
        '</td>';
      content +=
        '<td style="border: 1px solid #000; border-collapse: collapse; padding: 2px">' +
        row.firstName +
        '</td>';
      let birthDate = new Date(row.birthDt);
      const actualBirthDateFormat =
        (birthDate.getMonth() > 8
          ? birthDate.getMonth() + 1
          : '0' + (birthDate.getMonth() + 1)) +
        '/' +
        (birthDate.getDate() > 9
          ? birthDate.getDate()
          : '0' + birthDate.getDate()) +
        '/' +
        birthDate.getFullYear();
      content +=
        '<td style="border: 1px solid #000; border-collapse: collapse; padding: 2px">' +
        actualBirthDateFormat +
        '</td>';
      content +=
        '<td style="border: 1px solid #000; border-collapse: collapse; padding: 2px">' +
        row.personCd +
        '</td>';
      content +=
        '<td style="border: 1px solid #000; border-collapse: collapse; padding: 2px; width: 150px;">' +
        ` <div>
            ${row.groupNo}
          </div>
          <div>
            ${row.groupName}
          </div>` +
        '</td>';
      let dateAdded = new Date(row.dateAdded);
      const actualDateAddedFormat =
        (dateAdded.getMonth() > 8
          ? dateAdded.getMonth() + 1
          : '0' + (dateAdded.getMonth() + 1)) +
        '/' +
        (dateAdded.getDate() > 9
          ? dateAdded.getDate()
          : '0' + dateAdded.getDate()) +
        '/' +
        dateAdded.getFullYear();
      content +=
        '<td style="border: 1px solid #000; border-collapse: collapse; padding: 2px">' +
        actualDateAddedFormat +
        '</td>';
      content +=
        '<td style="border: 1px solid #000; border-collapse: collapse; padding: 2px">' +
        row.changes +
        '</td>';
      content +=
        '<td style="border: 1px solid #000; border-collapse: collapse; padding: 2px">' +
        row.comments +
        '</td>';
      if (this.isInternalUser) {
        const errCode = row.httpStatusCode ? row.httpStatusCode : '-';
        const errorMessage = row.errorMsg ? row.errorMsg : '-'
        const status = row.isSuccess === false ? 'Failed' : 'Successful'
        content +=
          '<td style="border: 1px solid #000; border-collapse: collapse; padding: 2px">' +
          errCode +
          '</td>';
        content +=
          '<td style="border: 1px solid #000; border-collapse: collapse; padding: 2px; width: 5px; overflow-wrap: break-word; word-wrap: break-word; white-space: normal;">' +
          errorMessage +
          '</td>';
        content +=
          '<td style="border: 1px solid #000; border-collapse: collapse; padding: 2px">' +
          status +
          '</td>';
      }
      content += '</tr>';
    }
    content += '</tbody>';

    content += '</table>';
    content += '</body></html>';

    printDoc.open();
    printDoc.write(content);
    printDoc.close();

    printFrame.contentWindow.print();
  }

  createStyledTd(text: string, styles: { [key: string]: string }): string {
    let td = '<td style="';
    for (let style in styles) {
      td += style + ':' + styles[style] + ';';
    }
    td += '">' + text + '</td>';
    return td;
  }

  resetForm() {
    this.searchAuditLogsFormGroup.get('firstName').reset();
    this.searchAuditLogsFormGroup.get('lastName').reset();
    this.searchAuditLogsFormGroup.get('cardId').reset();
    this.searchAuditLogsFormGroup.get('from').reset();
    this.searchAuditLogsFormGroup.get('to').reset();
  }
}
