<div class="page-wrapper">
  <a class="back-arrow-inner" style="cursor: pointer" (click)="navigateBackToMemberSearch()"><mat-icon
      matListIcon>arrow_back</mat-icon> Member Search</a>
  <div class="page-header">
    <h2>Pharmacy Search</h2>
  </div>

  <!-- Member Detail card -->
  <div class="detail-card" *ngIf="selectedMemberDetails$ | async as selectedMemberDetails">
    <div class="client-id-name">
      <h4>Member</h4>
      <span>(Client: {{selectedMemberDetails.clientId}}-{{selectedMemberDetails.client_full_name}})</span>
    </div>
    <div class="mat-row">
      <div class="mat-2">
        <label class="card-label">Name</label>
        <p>
          {{ selectedMemberDetails.firstName }}
          {{ selectedMemberDetails.lastName }}
        </p>
      </div>
      <div class="mat-2">
        <label class="card-label">Person Code</label>
        <p>{{ selectedMemberDetails.personCode }}</p>
      </div>
      <div class="mat-2">
        <label class="card-label">DOB</label>
        <p>{{ selectedMemberDetails.DOB | timeStampToDate }}</p>
      </div>
      <div class="mat-2">
        <label class="card-label">Relationship</label>
        <p>{{selectedMemberDetails.relationshipCode}} - {{relationShip[selectedMemberDetails.relationshipCode] }}</p>
      </div>
      <div class="mat-2">
        <label class="card-label">Card/Alt ID</label>
        <p>{{ selectedMemberDetails.cardId }}</p>
      </div>
      <div class="mat-2">
        <label class="card-label">Coverage Type</label>
        <p>
          {{
          displayCoverageType[selectedMemberDetails?.coverage?.coverageType]
          }}
        </p>
      </div>
    </div>
  </div>

  <!-- Search by -->
  <div class="search-by-container pad-b-0">
    <h4 class="sec-heading">Search By</h4>
    <div class="custom-tab">
      <form [formGroup]="filterForm">
        <div class="tab-action-group mat-row pad-b-1 no-tb-space pharmacy-filter-tab">
          <div class="mat-10 pad-r-1">
            <mat-tab-group [(selectedIndex)]="this.selectedIndex" (selectedIndexChange)="onTabChange($event)"
              animationDuration="0ms">
              <mat-tab label="City and State">
                <div class="tab-action-group mat-row pad-b-1">
                  <div class="mat-3 pad-r-1">
                    <mat-label>City<span class="asterisk-mark">*</span></mat-label>
                    <mat-form-field floatLabel="never" class="full-width">
                      <input matInput placeholder="Enter City" formControlName="city" />
                    </mat-form-field>
                    <app-custom-mat-hint [formGroup]="filterForm" [isCheckTouch]="checkTouchForValidation"
                      controlName="city"></app-custom-mat-hint>
                  </div>
                  <div class="mat-3 pad-r-1">
                    <mat-label>State<span class="asterisk-mark">*</span></mat-label>
                    <mat-form-field floatLabel="never" class="full-width zero-padding">
                      <mat-select placeholder="Select State" formControlName="state" panelClass="custom-select-panel"
                        [disableOptionCentering]="true" matNativeControl>
                        <mat-option>
                          <ngx-mat-select-search [formControl]="stateFilterControl" [clearSearchInput]="true"
                            [showToggleAllCheckbox]="false" placeholderLabel="Search state by Name..."
                            noItemsLabel="No states found">
                          </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngIf="(filteredStates | async)?.length === 0" disabled>
                          No states found
                        </mat-option>
                        <ng-container *ngIf="filteredStates | async as States">
                          <mat-option class="grp-dropdown" *ngFor="let state of filteredStates| async as States"
                            [value]="state.value">
                            {{state.name}}
                          </mat-option>
                        </ng-container>
                      </mat-select>
                    </mat-form-field>
                    <app-custom-mat-hint [formGroup]="filterForm" [isCheckTouch]="checkTouchForValidation"
                      controlName="state"></app-custom-mat-hint>
                  </div>
                  <div class="mat-3 pad-r-1">
                    <mat-label>Pharmacy Name (Optional)</mat-label>
                    <mat-form-field floatLabel="never" class="full-width">
                      <input matInput placeholder="Enter Pharmacy Name" formControlName="pharmacyName" />
                    </mat-form-field>
                  </div>
                </div>
              </mat-tab>

              <mat-tab label="Zip Code">
                <div class="tab-action-group mat-row pad-b-1">
                  <div class="mat-3 pad-r-1">
                    <mat-label>Zip Code<span class="asterisk-mark">*</span></mat-label>
                    <mat-form-field floatLabel="never" class="full-width">
                      <input matInput (keydown)="numericOnly($event)" placeholder="Enter Zip Code"
                        formControlName="zipCode" />
                    </mat-form-field>
                    <app-custom-mat-hint [formGroup]="filterForm" [isCheckTouch]="checkTouchForValidation"
                      controlName="zipCode"></app-custom-mat-hint>
                  </div>

                  <div class="mat-3 pad-r-1" *ngIf="!checkIfDefaultRadiusConfigured()">
                    <mat-label>Within<span class="asterisk-mark">*</span></mat-label>
                    <mat-form-field floatLabel="never" class="full-width zero-padding">
                      <mat-select formControlName="radius" placeholder="Select Within">
                        <mat-option [value]="5"> 5 </mat-option>
                        <mat-option [value]="10"> 10 </mat-option>
                        <mat-option [value]="15"> 15 </mat-option>
                        <mat-option [value]="20"> 20 </mat-option>
                        <mat-option [value]="25"> 25 </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <app-custom-mat-hint [formGroup]="filterForm" [isCheckTouch]="checkTouchForValidation"
                      controlName="radius"></app-custom-mat-hint>
                  </div>

                  <div class="mat-3 pad-r-1">
                    <mat-label>Pharmacy Name (Optional)</mat-label>
                    <mat-form-field floatLabel="never" class="full-width">
                      <input matInput placeholder="Enter Pharmacy Name" formControlName="pharmacyName" />
                    </mat-form-field>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>

          <div class="mat-2 serach-btn-top-spacing d-flex">
            <button (click)="getPharmacies(true); checkTouchForValidation = true" [disabled]="filterForm.invalid"
              class="primary-btn top-label-space" mat-button>
              Search
            </button>
            <!-- <mat-icon class="close-icon" matListIcon>close</mat-icon> -->
            <button (click)="resetForm()" [ngClass]="{ 'disabled-btn': allFieldsEmpty }"
              class="secondary-btn top-label-space" mat-button>
              Reset
            </button>
          </div>
        </div>

        <mat-divider class="mar-b-1"></mat-divider>
      </form>
    </div>
  </div>

  <div [hidden]="pharmacySearchResponse === undefined">
    <div class="mat-row" fxLayout="row" class="pharmacy-search-filters">
      <div class="mat-6">
        <h4 class="sec-heading mar-b-0 pad-t-1">Search Results</h4>
      </div>
      <div class="mat-6" fxLayoutAlign="end end" class="filter-toggle">
        <mat-button-toggle-group (change)="resetSearch()" [(ngModel)]="pharmacyType"  *ngIf="!clientIdsWithoutMailOrders?.includes(memberDetails?.clientId)" class="toggle-buttons">
          <mat-button-toggle value="retail">Retail</mat-button-toggle>
          <mat-button-toggle value="mail-order">Mail Order</mat-button-toggle>
        </mat-button-toggle-group>

        <button mat-button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu">
          <mat-icon matListIcon>filter_alt</mat-icon>
        </button>
        <mat-menu #menu="matMenu" xPosition="before" class="filter-box">
          <div (click)="$event.stopPropagation()">
            <div class="filter-heading">
              Filter
              <mat-icon class="close-icon" (click)="closeFilter()" matListIcon>close</mat-icon>
            </div>
            <div class="slide-toggle-field">
              In Network
              <mat-slide-toggle [(ngModel)]="isNetwork"></mat-slide-toggle>
            </div>
            <div class="slide-toggle-field">
              Operating 24 hrs
              <mat-slide-toggle [(ngModel)]="is24Hrs"></mat-slide-toggle>
            </div>
            <button class="primary-btn" mat-button (click)="resetSearch()" (keyup.enter)="resetSearch()">
              Apply
            </button>
            <button (click)="isNetwork = !true; is24Hrs = !true; resetSearch()"
              (keyup.enter)="isNetwork = !true; is24Hrs = !true; resetSearch()" class="secondary-btn" mat-button>
              Clear
            </button>
          </div>
        </mat-menu>
      </div>
    </div>

    <!--Table Component-->
    <div class="mar-t-1">
      <table class="pharmacy-search-details" mat-table [dataSource]="dataSource" matSort
        (matSortChange)="sortChange($event)">
        <!-- name Column -->
        <ng-container matColumnDef="images">
          <th scope="col" mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row" class="img-cell">
            <img [ngClass]="{ 'disbled-img': !row.hours.operating24Hours }" class="disabled-img"
              src="assets/images/24hour-icon.svg" alt="" />
            <img [ngClass]="{ 'disbled-img': !row.inNetwork }" src="assets/images/rx-icon.svg" alt="" />
          </td>
        </ng-container>
        <!-- name Column -->
        <ng-container matColumnDef="name">
          <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Pharmacy Name
          </th>
          <td mat-cell *matCellDef="let row">{{ row.name }}</td>
        </ng-container>

        <!-- address Column -->
        <ng-container matColumnDef="address">
          <th scope="col" mat-header-cell *matHeaderCellDef>Address</th>
          <td mat-cell *matCellDef="let row">
            {{ row.address.addressLine1 }},
            {{ row.address.addressLine2 ? row.address.addressLine2 + "," : "" }}
            {{ row.address.city }}, {{ row.address.state }},
            {{ row.address.zipCode }}
          </td>
        </ng-container>

        <!-- phoneNumber Column -->
        <ng-container matColumnDef="phoneNumber">
          <th scope="col" mat-header-cell *matHeaderCellDef>Phone Number</th>
          <td mat-cell *matCellDef="let row">
            {{ row.phoneNumber ? formatPhoneNumber(row.phoneNumber) : "-" }}
          </td>
        </ng-container>

        <!-- hours Column -->
        <ng-container matColumnDef="hours">
          <th class="center" scope="col" mat-header-cell *matHeaderCellDef>
            Pharmacy Hours
          </th>
          <td class="center" mat-cell *matCellDef="let row">
            {{
            row.hours.operating24Hours ? "24 Hrs" : getTodaysTiming(row.hours)
            }}
          </td>
        </ng-container>

        <!-- inNetwork Column -->
        <ng-container matColumnDef="inNetwork">
          <th class="center" scope="col" mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            In Network
          </th>
          <td class="center" mat-cell *matCellDef="let row">
            {{ row.inNetwork ? "Yes" : "No" }}
          </td>
        </ng-container>

        <!-- distance Column -->
        <ng-container matColumnDef="distance">
          <th class="center" scope="col" mat-header-cell *matHeaderCellDef mat-sort-header disableClear
            class="last-th-width">
            Distance (in Miles)
          </th>
          <td class="center" mat-cell *matCellDef="let row">
            {{ row.distance ? row.distance?.toFixed(1) : "-" }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr tabindex="0" (keydown.enter)="$event.stopPropagation(); openDialog(row)" (click)="openDialog(row)" mat-row
          *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr tabindex="0" class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="9" role="status"
            *ngIf="!pharmacySearchConfig?.clientIds.includes(memberDetails.clientId) || showOtherPharmacies">
            No Data Found
          </td>
          <td class="mat-cell no-pharmacies-found-error" colspan="9" role="status"
            *ngIf="pharmacySearchConfig?.clientIds.includes(memberDetails.clientId) && !showOtherPharmacies">
            <p [innerHtml]="pharmacySearchConfig.translations.NO_PHARMACIES_FOUND_EN"></p> <button
              (click)="loadOtherPharmacies()">Click Here</button>
          </td>
        </tr>
      </table>
      <div class="accordion-table-footer paginator" fxLayout="row" fxLayoutAlign="end center">
        <div class="non-pharmacies-text"
          *ngIf="pharmacySearchConfig?.clientIds.includes(memberDetails.clientId) && showOtherPharmacies && pharmacySearchResponse?.pharmacies?.length ">
          {{ pharmacySearchConfig.translations.OTHER_PHARMACIES_LIST_DISPLAYED_EN }}
        </div>
        <div class="accordion-table-footer" fxLayout="row" fxLayoutAlign="end center">
          <mat-paginator #paginator (page)="onPageChange($event)" [length]="this.totalLength"
            [pageIndex]="this.currentPageIndex" [pageSizeOptions]="[5, 10]">
          </mat-paginator>
        </div>
      </div>

    </div>
  </div>
</div>