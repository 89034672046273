<div class="page-wrapper">
  <!-- Page Header -->
  <div class="page-header">
    <h2>Online Eligibility</h2>
    <p>
      To search for a member, select a client and specify ID or enter First Name
      or Last Name
    </p>
    <button mat-stroked-button rounded class="add-new-member" color="accent" *ngIf="showAddMember"
      (click)="addNewMember()">
      Add a New Member
    </button>
  </div>
  <!-- Form Section -->
  <form [formGroup]="searchMemberFormGroup">
    <div class="form-wrapper">
      <div class="mat-row">
        <div class="mat-12">
          <mat-label>Client<span class="asterisk-mark">*</span></mat-label>
          <mat-form-field floatLabel="never" class="full-width">
            <mat-select placeholder="Select Client" formControlName="client"
              (selectionChange)="onSelectionChange($event)" matNativeControl>
              <ng-container *ngIf="listOfClients$ | async as clients">
                <mat-option *ngFor="let client of clients" [value]="client.clientId">
                  {{client.clientId}}-{{ client.clientName }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <app-custom-mat-hint [formGroup]="searchMemberFormGroup" [isCheckTouch]="checkTouchForForm"
            controlName="client"></app-custom-mat-hint>
        </div>
      </div>
    </div>

    <!-- Search by -->
    <div class="search-by-container">
      <h4 class="sec-heading-no-margin">Search By</h4>
      <div class="custom-tab">
        <mat-tab-group (selectedTabChange)="onTabChange($event)"
          [(selectedIndex)]="this.eligibilityService.selectedIndex" animationDuration="0ms">
          <mat-tab label="Card/Alt ID or SSN"> </mat-tab>
          <mat-tab label="First or Last Name"> </mat-tab>
        </mat-tab-group>

        <!--Form-->

        <div class="tab-action-group mat-row pad-b-1">
          <ng-container *ngIf="this.eligibilityService.selectedIndex === 0">
            <div class="mat-3 pad-r-1">
              <mat-label class="flex">ID Type
                <div class="popover">
                  <mat-icon class="info-icon">info</mat-icon>
                  <div class="popover-container">
                    <div class="flex mb-3">
                      <div class="w-30 text-right">
                        <b>Card/Alt ID</b>
                      </div>
                      <div class="w-70 pl-2">
                        A unique identifier assigned to each user's insurance
                        card.
                      </div>
                    </div>
                    <div class="flex mb-3">
                      <div class="w-30 text-right">
                        <b>SSN</b>
                      </div>
                      <div class="w-70 pl-2">
                        A unique nine-digit Social Security Number.
                      </div>
                    </div>
                    <!-- <div *ngIf="isInternalUser$ | async" class="flex mb-3">
                      <div class="w-30 text-right">
                        <b>EmpiRx Member ID</b>
                      </div>
                      <div class="w-70 pl-2">
                        A unique identifier for a specific member in the system.
                      </div>
                    </div> -->
                    <div class="flex mb-3">
                      <div class="w-30 text-right">
                        <b>Client Internal Member ID</b>
                      </div>
                      <div class="w-70 pl-2">
                        A unique identifier for the member for the internal use.
                      </div>
                    </div>
                    <!-- <div class="flex">
                      <div class="w-30 text-right">
                        <b>Patient ID</b>
                      </div>
                      <div class="w-70 pl-2">
                        Unique identifier for each patient in the system.
                      </div>
                    </div> -->
                  </div>
                </div>
              </mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <mat-select formControlName="idType" placeholder="Enter ID Type"
                  (selectionChange)="onChangeIdType($event)" matNativeControl placeholder="Enter ID Type">
                  <mat-option *ngFor="let item of idTypes" [value]="item">
                    {{ item }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <app-custom-mat-hint [formGroup]="searchMemberFormGroup" [isCheckTouch]="checkTouchForForm"
                controlName="idType"></app-custom-mat-hint>
            </div>
            <div class="mat-3 pad-r-1">
              <mat-label>ID Number</mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <input *ngIf="
                    searchMemberFormGroup.controls['idType'].value !== 'SSN'
                  " matInput formControlName="idValue" placeholder="Enter ID Number" />
                <input *ngIf="
                    searchMemberFormGroup.controls['idType'].value === 'SSN'
                  " matInput appSsnFormat formControlName="idValue" placeholder="Enter ID Number" />
              </mat-form-field>
              <app-custom-mat-hint [formGroup]="searchMemberFormGroup" [isCheckTouch]="checkTouchForForm"
                controlName="idValue"></app-custom-mat-hint>
              <mat-hint class="text-danger title-11" *ngIf="
                  searchMemberFormGroup.controls['idType'].value ===
                    'Card/Alt ID' &&
                  searchMemberFormGroup.value.client === '19121'
                ">
                Please do not enter UNE in the Card ID field. Enter the
                characters that appear after UNE.
              </mat-hint>
            </div>
          </ng-container>

          <ng-container *ngIf="this.eligibilityService.selectedIndex === 1">
            <div class="mat-3 pad-r-1">
              <mat-label>First Name</mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <input matInput formControlName="firstName" placeholder="Enter First Name" value="" />
              </mat-form-field>
            </div>
            <div class="mat-3 pad-r-1">
              <mat-label>Last Name</mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <input matInput formControlName="lastName" placeholder="Enter Last Name" value="" />
              </mat-form-field>
            </div>
          </ng-container>

          <div class="mat-6">
            <div class="action-btn-group">
              <button mat-button class="primary-btn" [disabled]="!isValidForm()"
                (click)="checkTouchForForm = true; searchMembers()">
                Search
              </button>
              <button mat-button class="secondary-btn" (click)="resetForm()"
                [ngClass]="{ 'disabled-btn': allFieldsEmpty }">
                Reset
              </button>
            </div>
          </div>
        </div>

        <mat-divider></mat-divider>
      </div>
    </div>
  </form>

  <!-- Accordion Table -->

  <ng-container *ngIf="this.members$ | async as members">
    <ng-container *ngIf="dataLoaded">
      <div class="accordion-table-filter">
        <h4>{{ members.length }} Results Found</h4>
        <button mat-button (click)="toggle()" class="expand-collapse-button">
          Expand/Collapse All
        </button>
      </div>

      <div class="accordion-table">
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8 member-search-table">
          <!--['personCode','firstName', 'lastName', 'cardId', 'menuOptions', 'expandIcon' ];-->

          <ng-container matColumnDef="personCode">
            <th mat-header-cell *matHeaderCellDef>Person Code</th>
            <td mat-cell *matCellDef="let element">
              {{ element.personCode }}
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">
              {{ element.firstName }} {{ element.lastName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="DOB">
            <th mat-header-cell *matHeaderCellDef>DOB</th>
            <td mat-cell *matCellDef="let element">
              {{ element.DOB | timeStampToDate }}
            </td>
          </ng-container>

          <ng-container matColumnDef="cardId">
            <th mat-header-cell *matHeaderCellDef>Card ID</th>
            <td mat-cell *matCellDef="let element">
              {{ element.cardId }}
            </td>
          </ng-container>

          <ng-container matColumnDef="relationShipCode">
            <th mat-header-cell *matHeaderCellDef>Relationship</th>
            <td mat-cell *matCellDef="let element">
              {{ element.relationshipCode }} - {{ relationShip[element.relationshipCode] }}
            </td>
          </ng-container>

          <ng-container matColumnDef="clientInternalMemberId">
            <th mat-header-cell *matHeaderCellDef>Client Internal Member ID</th>
            <td mat-cell *matCellDef="let element">
              {{
              element.clientInternalMemberId
              ? element.clientInternalMemberId
              : "-"
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="menuOptions">
            <th class="text-right pad-r-1" mat-header-cell *matHeaderCellDef>
              Actions
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="flex" fxLayout="row" fxLayoutAlign="end center" class="relative-action">
                <mat-icon class="add-user" matTooltip="Add New Dependent"
                  *ngIf="checkMenuAccess(element, 'AddNewDependent')" (click)="addNewDependent(element)"></mat-icon>
                <mat-icon class="edit-user" [matTooltip]="getToolTip()" *ngIf="checkMenuAccess(element, 'UpdateMember')"
                  (click)="updateMember(element)"></mat-icon>
                <div class="menu">
                  <button matTooltip="View all actions" *ngIf="getMenuOptions(element).length" mat-icon-button
                    [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu class="custom-menu" #menu="matMenu">
                    <ng-container *ngFor="let menu of getMenuOptions(element)">
                      <button mat-menu-item (click)="goToMenu(menu, element)">
                        {{ menu.displayName }}
                      </button>
                    </ng-container>
                  </mat-menu>
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="expandIcon">
            <th mat-header-cell *matHeaderCellDef class="th-acc-expand"></th>
            <td mat-cell *matCellDef="let element">
              <mat-icon (click)="
                  expandedElement =
                    expandedElement === element ? null : element;
                  allRowsExpanded = allRowsExpanded ? false : allRowsExpanded
                " class="down-arrow acc-expand-icon">expand_more</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" [ngClass]="{
              'coverage-expand-expired-active': checkIfCoverageExpired(element),
              'coverage-expand-start-active': checkIfCoverageIsStartingSoon(element)
            }">
              <div class="example-element-detail" [@detailExpand]="
                  element == expandedElement || allRowsExpanded
                    ? 'expanded'
                    : 'collapsed'
                ">
                <div class="custom-tab w-100" style="margin-left: -6px">
                  <mat-tab-group animationDuration="0ms">
                    <mat-tab *ngIf="element" label="Personal">
                      <div class="pad-1">
                        <div class="flex flex-wrap">
                          <div class="read-only w-25">
                            <p>SSN</p>
                            <h4>
                              {{
                              element.SSN ? formatSSNString(element.SSN) : "-"
                              }}
                            </h4>
                          </div>
                          <div class="read-only w-25">
                            <p>Gender</p>
                            <h4>{{ displayGender[element.gender] }}</h4>
                          </div>
                          <div class="read-only w-25">
                            <p>Patient ID</p>
                            <h4>
                              {{ element.patientId ? element.patientId : "-" }}
                            </h4>
                          </div>
                          <div class="read-only w-25">
                            <p>Coverage Type</p>
                            <h4>
                              {{
                              element.coverage?.coverageType
                              ? element.coverage?.coverageType
                              : "-"
                              }}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </mat-tab>

                    <mat-tab *ngIf="element" label="Address">
                      <div class="pad-1">
                        <div class="flex flex-wrap">
                          <div class="read-only w-25">
                            <p>Address Line 1</p>
                            <h4>
                              {{
                              element.addressLine1
                              ? element.addressLine1
                              : "-"
                              }}
                            </h4>
                          </div>
                          <div class="read-only w-25">
                            <p>Address Line 2</p>
                            <h4>
                              {{
                              element.addressLine2
                              ? element.addressLine2
                              : "-"
                              }}
                            </h4>
                          </div>
                          <div class="read-only w-25">
                            <p>City</p>
                            <h4>{{ element.city ? element.city : "-" }}</h4>
                          </div>
                          <div class="read-only w-25">
                            <p>State</p>
                            <h4>{{ element.state ? element.state : "-" }}</h4>
                          </div>
                          <div class="read-only w-25">
                            <p>Zip code</p>
                            <h4>
                              {{ element.zipCode ? element.zipCode : "-" }}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </mat-tab>

                    <mat-tab *ngIf="element" label="Contact">
                      <div class="pad-1">
                        <div class="flex flex-wrap">
                          <div class="read-only w-25" *ngFor="let phone of element.phones">
                            <p *ngIf="phone.type === 'workPhone'">Work Phone</p>
                            <p *ngIf="phone.type === 'cellPhone'">Cell Phone</p>
                            <p *ngIf="phone.type === 'homePhone'">Home Phone</p>
                            <h4>
                              {{
                              phone.phoneNumber &&
                              phone.phoneNumber !== "null"
                              ? formatPhoneNumber(phone.phoneNumber)
                              : "-"
                              }}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </mat-tab>

                    <mat-tab *ngIf="element" label="Coverage">
                      <div *ngIf="
                          element.coverageHistory$ | async as coverageHistory
                        " class="pad-1">
                        <table mat-table [dataSource]="
                            getCoverageTableDatasource(coverageHistory)
                          " multiTemplateDataRows class="coverage-table">
                          <ng-container matColumnDef="group">
                            <th mat-header-cell *matHeaderCellDef>Group</th>
                            <td mat-cell *matCellDef="let element">
                              {{ element.groupName }}
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="client">
                            <th mat-header-cell *matHeaderCellDef>Client</th>
                            <td mat-cell *matCellDef="let element">
                              {{ element.clientName }}
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="effectiveDate">
                            <th mat-header-cell *matHeaderCellDef>
                              Effective Date
                            </th>
                            <td mat-cell *matCellDef="let element">
                              {{ element.startDate }}
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="endDate">
                            <th mat-header-cell *matHeaderCellDef>End Date</th>
                            <td mat-cell *matCellDef="let element">
                              {{ element.endDate }}
                            </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="coverageTableColumns"></tr>
                          <tr mat-row *matRowDef="let row; columns: coverageTableColumns"></tr>
                          <tr class="mat-row no-data-row" *matNoDataRow>
                            <td class="mat-cell" colspan="9" role="status">
                              No Data Found
                            </td>
                          </tr>
                        </table>
                      </div>
                    </mat-tab>
                    <!-- *ngIf="element && element.personCode === '01'" -->
                    <mat-tab *ngIf="
                        element && element.relationshipCode === cardHolderRC  " label="ID Requests">
                      <div *ngIf="element.idRequests$ | async as idRequests" class="pad-1">
                        <div class="flex flex-wrap">
                          <div *ngIf="idRequests.length === 0" class="read-only w-30">
                            <p>No requests available</p>
                          </div>
                          <div *ngIf="idRequests.length > 0" class="read-only w-30">
                            <p>List of dates</p>
                            <ul>
                              <li *ngFor="let reqDate of idRequests">
                                {{ reqDate | timeStampToDate }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </mat-tab>
                  </mat-tab-group>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let element; columns: displayedColumns" [ngClass]="{
              'coverage-expired-active': checkIfCoverageExpired(element),
              'coverage-start-active': checkIfCoverageIsStartingSoon(element)
            }" class="example-element-row" [class.example-expanded-row]="expandedElement === element"></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

          <tr tabindex="0" class="mat-row no-data-row" *matNoDataRow>
            <td class="mat-cell" colspan="9" role="status">No Data Found</td>
          </tr>
        </table>
        <div class="accordion-table-footer">
          <div class="legends">
            <div class="legends-item">
              <span class="ce-legend"></span>
              Coverage Expired
            </div>
            <div class="legends-item">
              <span class="cs-legend"></span>
              Coverage Starting Soon
            </div>
          </div>
          <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>