<div class="page-wrapper">
  <div class="page-header">
    <h2>Audit Logs</h2>
    <p>
      To run a report, select a client and enter the appropriate search criteria
    </p>
  </div>
  <form [formGroup]="searchAuditLogsFormGroup">
    <div class="form-wrapper">
      <div class="mat-row">
        <div class="mat-12">
          <mat-label>Client<span class="asterisk-mark">*</span></mat-label>
          <mat-form-field floatLabel="never" class="full-width">
            <mat-select placeholder="Select Client" panelClass="custom-select-panel" formControlName="client"
              (selectionChange)="onClientSelection($event)" [disableOptionCentering]="true" matNativeControl>
              <mat-option>
                <ngx-mat-select-search [formControl]="clientFilterControl" [clearSearchInput]="true"
                  [showToggleAllCheckbox]="false" placeholderLabel="Search Client(s) by ID or Name..."
                  noItemsLabel="No clients found">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngIf="(filteredClients | async)?.length === 0" disabled>
                No clients found
              </mat-option>
              <mat-option *ngFor="let client of filteredClients | async" [value]="client">
                {{ client.clientId }} - {{ client.clientName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <app-custom-mat-hint [formGroup]="searchAuditLogsFormGroup" [isCheckTouch]="checkTouchForForm"
            controlName="client"></app-custom-mat-hint>
        </div>
      </div>
    </div>

    <!-- Search by -->
    <div class="search-by-container">
      <h4 class="sec-heading-no-margin">Search By</h4>
      <div class="custom-tab">
        <!--Form-->

        <div class="tab-action-group pad-b-1">
          <div class="mat-row" fxLayout="row" fxLayout.sm="row">
            <div class="mat-3 pad-r-1">
              <mat-label>Card ID</mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <input matInput formControlName="cardId" placeholder="Enter Card ID" value=""
                  (input)="markFieldAsTouched()" />
              </mat-form-field>
              <app-custom-mat-hint [formGroup]="searchAuditLogsFormGroup" [isCheckTouch]="checkTouchForForm"
                controlName="cardId"></app-custom-mat-hint>
              <mat-hint class="text-danger title-11" *ngIf="
                  searchAuditLogsFormGroup.controls['client'].value ===
                    '19121' &&
                  searchAuditLogsFormGroup.controls['cardId'].valid
                ">
                Please do not enter UNE in the Card ID field. Enter the
                characters that appear after UNE.
              </mat-hint>
            </div>
            <div class="mat-3 pad-r-1">
              <mat-label>First Name</mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <input matInput formControlName="firstName" placeholder="Enter First name" value="" />
              </mat-form-field>
            </div>
            <div class="mat-3 pad-r-1">
              <mat-label>Last Name</mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <input matInput formControlName="lastName" placeholder="Enter Last name" value="" />
              </mat-form-field>
            </div>
          </div>
          <div class="mat-row" fxLayout="row" fxLayout.sm="row">
            <div class="mat-3 pad-r-1">
              <mat-label>User Email</mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <input matInput formControlName="email" placeholder="Enter User Email" value="" />
              </mat-form-field>
            </div>
            <div class="mat-3 pad-r-1">
              <mat-label>Category</mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <mat-select placeholder="Select Category" formControlName="category" matNativeControl>
                  <mat-option *ngFor="let category of categories$ | async" [value]="category">
                    {{category}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="mat-3 pad-r-1">
              <mat-label>Status</mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <mat-select placeholder="Select Status" formControlName="status" matNativeControl>
                  <mat-option value="true">
                    Successful
                  </mat-option>
                  <mat-option value="false">
                    Failed
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="mat-row" fxLayout="row" fxLayout.sm="row">
            <div class="mat-9">
              <div class="mat-row" fxLayout="row">
                <div class="mat-6 pad-r-1">
                  <mat-label>From<span class="asterisk-mark">*</span></mat-label>
                  <mat-form-field floatLabel="never" [ngClass]="{
                      'error-field': searchAuditLogsFormGroup
                        .get('from')
                        .hasError('dateInvalid')
                    }" class="full-width mat-datepicker-alignment">
                    <input matInput
                      [max]="searchAuditLogsFormGroup.value.to? searchAuditLogsFormGroup.value.to: maxDate"
                      [matDatepicker]="fromPicker" formControlName="from" placeholder="Enter Date"
                      (dateChange)="checkDateValues()" maxlength="10" />
                    <mat-datepicker-toggle matIconSuffix [for]="fromPicker"></mat-datepicker-toggle>
                    <mat-datepicker #fromPicker></mat-datepicker>
                  </mat-form-field>
                  <app-custom-mat-hint [formGroup]="searchAuditLogsFormGroup" [isCheckTouch]="checkTouchForForm"
                    controlName="from"></app-custom-mat-hint>
                </div>
                <div class="mat-6 pad-r-1" [ngClass]="{'disabled-date':!searchAuditLogsFormGroup.value.from}">
                  <mat-label>To<span class="asterisk-mark">*</span></mat-label>
                  <mat-form-field floatLabel="never" [ngClass]="{
                      'error-field': searchAuditLogsFormGroup
                        .get('to')
                        .hasError('dateInvalid')
                    }" class="full-width mat-datepicker-alignment">
                    <input matInput [max]="maxDate" [disabled]="!searchAuditLogsFormGroup.value.from"
                      [matDatepicker]="toPicker" [matDatepickerFilter]="endDateFilter" formControlName="to"
                      placeholder="Enter Date" (dateChange)="checkDateValues()" maxlength="10" />
                    <mat-datepicker-toggle matIconSuffix [for]="toPicker"></mat-datepicker-toggle>
                    <mat-datepicker #toPicker></mat-datepicker>
                  </mat-form-field>
                  <app-custom-mat-hint [formGroup]="searchAuditLogsFormGroup" [isCheckTouch]="checkTouchForForm"
                    controlName="to"></app-custom-mat-hint>
                </div>
              </div>
            </div>
            <div class="mat-3">
              <div class="action-btn-group">
                <button mat-button class="primary-btn" (click)="searchAuditLogs()"
                  [disabled]="searchAuditLogsFormGroup.invalid">
                  Search
                </button>
                <button mat-button (click)="resetForm()" class="secondary-btn"
                  [ngClass]="{ 'disabled-btn': allFieldsEmpty }">
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
      </div>
    </div>
  </form>

  <ng-container *ngIf="this.auditLogs$ | async as auditLogs">
    <ng-container *ngIf="dataLoaded">
      <div class="mat-row" fxLayout="row">
        <div class="mat-10">
          <h4>{{ auditLogs.length }} Results Found</h4>
        </div>
        <div *ngIf="selection.selected.length > 0" class="mat-2" fxLayout="row" fxLayoutAlign="end none">
          <mat-icon style="cursor: pointer" (click)="printSelectedRows()">print</mat-icon>
        </div>
      </div>
      <div class="audit-logs-table">
        <table mat-table matSort [dataSource]="dataSource" multiTemplateDataRows width="100%">
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(element) : null"
                [checked]="selection.isSelected(element)"></mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
            <td mat-cell *matCellDef="let element">
              <div class="wrap-text"> {{ element.lastName | titlecase }}</div>
            </td>
          </ng-container>

          <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
            <td mat-cell *matCellDef="let element">
              <div class="wrap-text">{{ element.firstName | titlecase }}</div>
            </td>
          </ng-container>

          <ng-container matColumnDef="cardId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Card ID</th>
            <td mat-cell *matCellDef="let element">
              {{ element.cardId? element.cardId: '-' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="birthDt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>DOB</th>
            <td mat-cell *matCellDef="let element">
              {{ element.birthDt | timeStampToDate }}
            </td>
          </ng-container>

          <ng-container matColumnDef="personCode">
            <th mat-header-cell *matHeaderCellDef>Person Code</th>
            <td mat-cell *matCellDef="let element">
              {{ element.personCd? element.personCd: '-' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="groupNumAndName">
            <th mat-header-cell *matHeaderCellDef>Group Number and Name</th>
            <td mat-cell *matCellDef="let element">
              <div class="wrap-text">
                {{ element.groupNo? element.groupNo: '' }} {{element.groupNo? ':' : ''}}
                <br>
                {{ element.groupName? element.groupName: '-' }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="dateAdded">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Entry Date</th>
            <td mat-cell *matCellDef="let element">
              {{ element.dateAdded | utcToLocal }}
            </td>
          </ng-container>

          <ng-container matColumnDef="change">
            <th mat-header-cell *matHeaderCellDef>Change</th>
            <td mat-cell *matCellDef="let element">
              {{ element.changes }}
            </td>
          </ng-container>

          <ng-container matColumnDef="changedBy">
            <th mat-header-cell *matHeaderCellDef>Changed By</th>
            <td mat-cell *matCellDef="let element">
              <div class="wrap-text">
                {{ element.addedBy }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="comments">
            <th mat-header-cell *matHeaderCellDef>Comments</th>
            <td mat-cell *matCellDef="let element">
              <div class="wrap-text">
                {{ parseComments(element) }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="errorCode">
            <th mat-header-cell *matHeaderCellDef>Error Code</th>
            <td mat-cell *matCellDef="let element">
              {{ element.httpStatusCode? element.httpStatusCode: '-' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="errorMessage">
            <th mat-header-cell *matHeaderCellDef>Error Message</th>
            <td mat-cell *matCellDef="let element">
              <div class="error-wrap-text" matTooltip="{{ element.errorMsg ? element.errorMsg : '-' }}"
                matTooltipShowDelay="0" matTooltipHideDelay="3000" (touchstart)="tooltip.show()"
                (touchend)="tooltip.hide()" #tooltip="matTooltip">
                {{ element.errorMsg ? element.errorMsg : '-' }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="isSuccess">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">
              {{ element.isSuccess === false? 'Failed': 'Successful' }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>

          <tr tabindex="0" class="mat-row no-data-row" *matNoDataRow>
            <td class="mat-cell" colspan="14" role="status">No Data Found</td>
          </tr>
        </table>
      </div>
      <div class="accordion-table-footer" fxLayout="row" fxLayoutAlign="end center">
        <mat-paginator #paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>
    </ng-container>
  </ng-container>
</div>
<iframe id="printFrame" style="display: none"></iframe>