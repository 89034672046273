import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AppConstants } from 'src/app/app.constants';
import { PharmacyHours } from 'src/app/interfaces/pharmacy-hours.interface';
import { PharmacySearchResponse } from 'src/app/interfaces/pharmacy-search-response.interface';
import {
  IPharmacySearchConfig,
  Pharmacy,
  PharmacySearchRequest,
} from 'src/app/interfaces/pharmacy.interface';
import { AppCenterAnalytics } from 'src/app/services/app-insights/app-insights';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';
import { PharmacyDetailsComponent } from '../pharmacy-details/pharmacy-details.component';
import { EligibilityService } from 'src/app/core/services/eligibility/eligibility.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '../../services/loader/loader.service';
import { Observable, of, Subscription } from 'rxjs';
import { switchMap, map, tap, startWith } from 'rxjs/operators';
import { MemberDetails } from 'src/app/interfaces/member.interface';
import { MatSort, Sort } from '@angular/material/sort';
import {
  numericOnly as externalNumericOnly,
  formatPhone,
  searchClientId,
} from 'src/app/util';
import { BenefitManagementService } from 'src/app/core/services/benefit-management/benefit-management.service';
import { SharedService } from '../../../services/shared/shared.service';
import { CMSService } from '../../../core/services/cms/cms.service';

@Component({
  selector: 'app-pharmacy-search',
  templateUrl: './pharmacy-search.component.html',
  styleUrls: ['./pharmacy-search.component.scss'],
})
export class PharmacySearchComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;
  @ViewChild(MatSort) sort: MatSort;

  lat = 0;
  lng = 0;
  isLoading = false;
  isError = false;
  today = AppConstants.DAYS[new Date().getDay()];
  selectedIndex: number = 0;
  displayedColumns: string[] = [
    'images',
    'name',
    'phoneNumber',
    'address',
    'hours',
    'inNetwork',
    'distance',
  ];
  public displayCoverageType = AppConstants.DISPLAY_COVERAGE_TYPE;
  dataSource: MatTableDataSource<Pharmacy> = new MatTableDataSource(
    [] as Pharmacy[]
  );

  public showOtherPharmacies = false;

  pharmacySearchResponse!: PharmacySearchResponse;
  currentPharmacies: Pharmacy[] = [];

  filterForm: FormGroup;

  isNetwork = true;
  is24Hrs = false;
  errMsg!: string;

  isByZip = true;
  private subscription: Subscription[] = [];
  pharmacyType: 'retail' | 'mail-order' = 'retail';
  mailOrderNPI = AppConstants.PHARMACY_MAIL_ORDER_NPI_ARRAY;
  isFilterApplied = false;
  pharmacy: Pharmacy;
  public checkTouchForValidation: boolean = false;
  public states: { name: string; value: string }[] = AppConstants.STATES;
  allFieldsEmpty: boolean = true;
  clientId: string = '';
  cardId: string = '';
  personCode: string = '';
  public memberDetails: MemberDetails;
  public clientIdsWithoutMailOrders: string[] = [];

  public relationShip = AppConstants.RELATIONSHIP_VALUES;

  public selectedMemberDetails$: Observable<Partial<MemberDetails>>;

  perPage: number = 5;
  currentPageIndex: number = 0;
  totalLength: number = 0;
  sortConfig: { sortByField: string; sortType: string };
  public pharmacySearchConfig: IPharmacySearchConfig;
  numericOnly = externalNumericOnly;

  public filteredStates!: Observable<Array<{ name: string; value: string }>>;
  public stateFilterControl = new FormControl();

  get zipCode(): FormControl {
    return this.filterForm.get('zipCode') as FormControl;
  }

  get pharmacyName(): FormControl {
    return this.filterForm.get('pharmacyName') as FormControl;
  }

  get radius(): FormControl {
    return this.filterForm.get('radius') as FormControl;
  }

  get city(): FormControl {
    return this.filterForm.get('city') as FormControl;
  }

  get state(): FormControl {
    return this.filterForm.get('state') as FormControl;
  }

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly appInsightService: AppInsightsService,
    private readonly dialog: MatDialog,
    private eligibilityService: EligibilityService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private readonly loaderService: LoaderService,
    private sharedService: SharedService,
    private cmsService: CMSService
  ) { }

  ngOnInit(): void {
    this.appInsightService.trackPageView(
      AppCenterAnalytics.FIND_PHARMACY_VIEWED
    );
    this.subscription.push(this.cmsService.getWordpressClientConfig().subscribe((res) => this.clientIdsWithoutMailOrders = res.SUPPRESS_MAIL_ORDER_FOR_CLIENTS.split(',').map((id) => id.trim())));

    this.filterForm = this.formBuilder.group({
      zipCode: [''],
      pharmacyName: [''],
      radius: ['5'],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
    });

    this.filterForm.valueChanges.subscribe((formValue) => {
      const formControls = Object.keys(formValue);
      const areAllFieldsEmpty = formControls.every((controlName) => {
        const control = this.filterForm.get(controlName);
        return (
          control.value === null ||
          control.value === undefined ||
          control.value === ''
        );
      });

      if (areAllFieldsEmpty) {
        this.allFieldsEmpty = true;
        // console.log('All fields are empty');
      } else {
        this.allFieldsEmpty = false;
        // console.log('All fields are not empty');
      }
    });

    this.selectedMemberDetails$ = this.activatedRoute.queryParams.pipe(
      switchMap((queryParams) => {
        const query = { loading: true, ...queryParams }
        return this.eligibilityService.getMemberDetailsById(query).pipe(
          switchMap((memberDetails) => {
            return this.cmsService.getPharmacySearchConfig().pipe(
              map((config) => {
                this.pharmacySearchConfig = this.getPharmacySearchConfig(config, memberDetails[0].clientId);
                return memberDetails[0]; // Return the desired value from the observable chain
              })
            );
          }),
          tap((member) => {
            this.memberDetails = member;
            this.clientId = member.clientId;
            this.cardId = member.cardId;
            this.personCode = member.personCode;
          })
        );
      })
    );
    this.fetchStatesDropdownValue();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  fetchStatesDropdownValue(): void {
    this.filteredStates = this.stateFilterControl.valueChanges.pipe(
      startWith(''),
      switchMap(searchTerm => this.filterState(searchTerm)) // Filter group)
    )
  }

  private filterState(searchTerm: string): Observable<Array<{ name: string; value: string }>> {
    const lowerCaseTerm = searchTerm.toLowerCase();
    return of(this.states.filter(s =>
      s.name.toLowerCase().includes(lowerCaseTerm)
    ))
  }

  private getPharmacySearchConfig(
    pharmacySearchConfig: IPharmacySearchConfig[],
    memberClientId: string
  ): IPharmacySearchConfig | null {
    let clientConfiguration: IPharmacySearchConfig | null = null;

    const clientIndex = (pharmacySearchConfig || []).findIndex((x) =>
      searchClientId(x.clientIds, memberClientId)
    );

    if (clientIndex != -1) {
      // get the configuration against client ID
      clientConfiguration = pharmacySearchConfig[clientIndex];
    }
    return clientConfiguration;
  }

  formatPhoneNumber(phone: string): string {
    return formatPhone(phone);
  }

  onTabChange(event: any) {
    this.pharmacySearchResponse = undefined;
    this.filterForm.reset();

    switch (event) {
      case 0:
        this.setCityStateValidator();
        this.removeZipRadiusValidator();
        break;
      case 1:
        this.setZipRadiusValidator();
        this.removeCityStateValidator();
        break;
    }
  }

  setCityStateValidator() {
    this.filterForm.get('city').setValidators([Validators.required]);
    this.filterForm.get('state').setValidators([Validators.required]);
    this.filterForm.get('city').updateValueAndValidity();
    this.filterForm.get('state').updateValueAndValidity();
  }
  setZipRadiusValidator() {
    this.filterForm
      .get('zipCode')
      .setValidators([
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(5),
        Validators.pattern('^[0-9]+$'),
      ]);
    this.filterForm.get('radius').setValidators([Validators.required]);
    this.filterForm.get('zipCode').updateValueAndValidity();
    this.filterForm.get('radius').updateValueAndValidity();
    if (this.pharmacySearchConfig?.clientIds.includes(this.memberDetails.clientId) && this.pharmacySearchConfig.defaultSearchRadius) {
      this.filterForm.get('radius').clearValidators();
      this.filterForm.get('radius').updateValueAndValidity();
    }
  }

  removeCityStateValidator() {
    this.filterForm.get('city').clearValidators();
    this.filterForm.get('state').clearValidators();
    this.filterForm.get('city').updateValueAndValidity();
    this.filterForm.get('state').updateValueAndValidity();
  }

  removeZipRadiusValidator() {
    this.filterForm.get('zipCode').clearValidators();
    this.filterForm.get('radius').clearValidators();
    this.filterForm.get('zipCode').updateValueAndValidity();
    this.filterForm.get('radius').updateValueAndValidity();
  }

  public openDialog(pharmacy: Pharmacy): void {
    const dialogRef = this.dialog.open(PharmacyDetailsComponent, {
      height: '80vh',
      width: '80vw',
      data: pharmacy,
    });
  }

  public onPageChange(pageEvent: PageEvent) {
    this.paginator.pageIndex = this.currentPageIndex = pageEvent.pageIndex;
    this.getPharmacies(false, this.sortConfig);
  }

  public resetSearch(sort?: { sortByField: string; sortType: string }) {
    this.currentPageIndex = this.paginator.pageIndex = 0;
    this.getPharmacies(false, sort || this.sortConfig);
  }

  public checkIfDefaultRadiusConfigured() {
    return this.pharmacySearchConfig?.clientIds.includes(this.memberDetails.clientId) && this.pharmacySearchConfig?.defaultSearchRadius;
  }

  public getSearchRequest(
    filter: PharmacySearchRequest,
    sort?: { sortByField: string; sortType: string }
  ): PharmacySearchRequest {
    filter.pharmacyName = filter?.pharmacyName?.trim();
    filter.city = filter?.city?.trim();
    filter.operating24Hours = this.is24Hrs;
    filter.inNetwork = this.isNetwork;
    filter.perPage = this.paginator.pageSize ? this.paginator.pageSize : 5;
    filter.page = this.currentPageIndex + 1;

    if (sort) {
      filter.sort = sort as any;
    }
    return filter;
  }

  public getPharmacies(manualClick?: boolean, sort?: { sortByField: string; sortType: string }): void {
    this.loaderService.showLoader();
    if (manualClick) {
      this.showOtherPharmacies = false;
    }
    let filter: PharmacySearchRequest = {
      ...this.filterForm.value,
      clientId: this.clientId,
      cardId: this.cardId,
      personCode: this.personCode,
    };
    this.appInsightService.trackPageView(AppCenterAnalytics.GET_PHARMACY_SEARCH_REQUEST, { data: filter });
    this.isLoading = true;
    this.isError = false;
    if (this.pharmacySearchConfig?.clientIds.includes(this.memberDetails.clientId) && this.pharmacySearchConfig.defaultSearchRadius) {
      this.appInsightService.trackPageView(
        AppCenterAnalytics.PHARMACY_SEARCH_SET_DEFAULT_RADIUS,
        { data: this.pharmacySearchConfig.defaultSearchRadius }
      );
      filter.radius = this.pharmacySearchConfig.defaultSearchRadius;
    }
    const url = this.pharmacySearchConfig?.clientIds.includes(this.memberDetails.clientId) && this.pharmacySearchConfig.pharmacySearchAPI && !this.showOtherPharmacies ? this.pharmacySearchConfig.pharmacySearchAPI : '';
    this.eligibilityService
      .pharmacySearch(this.getSearchRequest(filter, sort), url)
      .subscribe(
        (pharmacySearchResponse: PharmacySearchResponse) => {
          this.pharmacySearchResponse = pharmacySearchResponse;
          this.isLoading = false;
          this.applyFilter();
        },
        (err) => {
          this.dataSource = new MatTableDataSource([] as Pharmacy[]);
          setTimeout(() => {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }, 0);
          this.isError = true;
          this.errMsg = err;
          this.isLoading = false;
          this.loaderService.hideLoader();
        },
        () => {
          this.loaderService.hideLoader();
        }
      );
  }

  public sortChange(sortState: Sort) {
    // console.log('Sort', sortState);
    if (sortState.direction) {
      this.sortConfig = {
        sortByField: sortState.active,
        sortType: sortState.direction.toUpperCase(),
      };
      this.resetSearch(this.sortConfig);
    }
  }

  sortData(): void {
    const data = this.dataSource.data.slice();
    // console.log('Data:', data);
    if (!this.sort.active || this.sort.direction === '') {
      this.dataSource.data = data; // No sorting applied
      return;
    }

    switch (this.sort.active) {
      case 'distance':
        this.sortByDistance(data);
        break;
      case 'inNetwork':
        this.sortByInNetwork(data);
        break;
      default:
        this.dataSource.data = data;
        break;
    }
  }

  private sortByDistance(data: Pharmacy[]): void {
    this.dataSource.data = data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      const distanceA = Number(a.distance);
      const distanceB = Number(b.distance);

      if (isNaN(distanceA) || distanceA === null) {
        return isAsc ? -1 : 1;
      }
      if (isNaN(distanceB) || distanceB === null) {
        return isAsc ? 1 : -1;
      }

      return isAsc ? distanceA - distanceB : distanceB - distanceA;
    });
  }

  private sortByInNetwork(data: Pharmacy[]): void {
    this.dataSource.data = data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';

      const inNetworkValueA = a.inNetwork ? 1 : 0;
      const inNetworkValueB = b.inNetwork ? 1 : 0;

      return isAsc
        ? inNetworkValueA - inNetworkValueB
        : inNetworkValueB - inNetworkValueA;
    });
  }

  public applyFilter(): void {
    let displayData = this.pharmacySearchResponse.pharmacies;

    displayData =
      this.pharmacyType === 'retail'
        ? displayData.filter((o) => !this.mailOrderNPI.includes(o.npi))
        : displayData.filter((o) => this.mailOrderNPI.includes(o.npi));

    if (this.paginator) {
      const totalLengthMailOrder = this.getMailOrderCount(
        this.pharmacySearchResponse
      );
      if (this.pharmacyType !== 'retail') {
        this.totalLength = totalLengthMailOrder;
      } else {
        if (this.pharmacySearchResponse.pagination?.currentPage === 1) {
          this.totalLength = this.pharmacySearchResponse.pagination
            ? this.pharmacySearchResponse.pagination.totalRecords -
            totalLengthMailOrder
            : this.paginator.pageSize;
        } else {
          this.totalLength = this.pharmacySearchResponse.pagination
            ? this.pharmacySearchResponse.pagination.totalRecords
            : this.paginator.pageSize;
        }
      }
    }

    this.dataSource = new MatTableDataSource(displayData);
    this.closeFilter();
  }

  private getMailOrderCount(pharmacies: PharmacySearchResponse) {
    // for mail order
    let mailOrderPharmacies = pharmacies.pharmacies.filter((o) =>
      this.mailOrderNPI.includes(o.npi)
    );
    return mailOrderPharmacies.length;
  }

  public closeFilter(): void {
    this.menuTrigger?.closeMenu();
  }

  public loadOtherPharmacies(): void {
    this.showOtherPharmacies = true;
    this.appInsightService.trackPageView(
      AppCenterAnalytics.PHARMACY_VIEW_OTHER_PHARMACY_CLICKED,
 
      { data: { parentElement: 'LINK', value: true } }
    );
    this.getPharmacies();
  }

  getTodaysTiming(hrs: PharmacyHours): string {
    const val: string[] = hrs[
      AppConstants.DAYS[new Date().getDay()] as keyof PharmacyHours
    ] as string[];
    if (!val[0] || !val[1]) {
      return 'Unknown Working Hours';
    }
    const str1 = (+val[0].split(':')[0] % 12).toString() || '12';
    const str2 = (+val[1].split(':')[0] % 12).toString() || '12';
    return str1 + ':00AM' + ' - ' + str2 + ':00PM';
  }

  public resetCityZip(): void {
    this.city.setValue('');
    this.state.setValue('');
    this.radius.setValue('5');
    this.zipCode.setValue('');
  }

  public navigateBackToMemberSearch(): void {
    this.router.navigateByUrl('/management/online-eligibility');
  }

  resetForm() {
    // console.log('Inside resetForm');
    this.filterForm.reset();
    this.checkTouchForValidation = false;
  }

  get isLevelD(): boolean {
    return this.sharedService.isLevelD();
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.forEach((s) => s.unsubscribe());
  }
}
