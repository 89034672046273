/**
 * @file enums.ts
 */
export enum MailOrderStatus {
    IN_PROGRESS = 'In-Progress',
    SHIPPED = 'Shipped',
    DELIVERED = 'Delivered',
    WAITING_FOR_PAYMENT = 'Waiting for Payment',
    READY_FOR_PICK_UP = 'Ready for Pickup',
    PICKED_UP = 'Picked-Up',
    CONTACTING_PRESCRIBER = 'Contacting Prescriber',
    PAYMENT = 'Payment',
    HOLD_TILL_REQUESTED = 'On Hold Until Requested',
    CANCELLED = 'Cancelled',
  }
  
export enum PRESCRIPTION_STATUS {
    TOO_SOON = 'Too Soon',
    IN_PROGRESS = 'In progress',
    REFILL = 'Refill',
    REQUEST_RENEW_PRESCRIPTION = 'Renew',
    AUTO_REFILL = 'Auto Refill',
    TRANSFERRED = 'Transferred',
    INACTIVE = 'Inactive',
    NO_MORE_REFILLS = 'No more refills'
}