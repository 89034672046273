<div class="page-wrapper">
  <a class="back-arrow-inner" style="cursor: pointer" (click)="navigateBackToMemberSearch()"><mat-icon
      matListIcon>arrow_back</mat-icon> Member Search</a>
  <!-- Page Header -->
  <div class="page-header">
    <h2>Prescriptions</h2>
  </div>
  <ng-container *ngIf="selectedMemberDetails$ | async as selectedMemberDetails">
    <!-- Member Detail card -->
    <div class="detail-card">
      <div class="client-id-name">
        <h4>Member</h4>
        <span>(Client: {{selectedMemberDetails.clientId}}-{{selectedMemberDetails.client_full_name}})</span>
      </div>
      <div class="mat-row">
        <div class="mat-2">
          <label class="card-label">Name</label>
          <p>
            {{ selectedMemberDetails.firstName }}
            {{ selectedMemberDetails.lastName }}
          </p>
        </div>
        <div class="mat-2">
          <label class="card-label">Person Code</label>
          <p>{{ selectedMemberDetails.personCode }}</p>
        </div>
        <div class="mat-2">
          <label class="card-label">DOB</label>
          <p>{{ selectedMemberDetails.DOB | timeStampToDate }}</p>
        </div>
        <div class="mat-2">
          <label class="card-label">Relationship</label>
          <p>{{selectedMemberDetails.relationshipCode}} - {{relationShip[selectedMemberDetails.relationshipCode] }}</p>
        </div>
        <div class="mat-2">
          <label class="card-label">Card/Alt ID</label>
          <p>{{ selectedMemberDetails.cardId }}</p>
        </div>
        <div class="mat-2">
          <label class="card-label">Coverage Type</label>
          <p>
            {{
            displayCoverageType[selectedMemberDetails?.coverage?.coverageType]
            }}
          </p>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>

    <div class="form-wrapper">
      <form [formGroup]="prescriptionsSearchFormGroup">
        <div class="mat-row pad-b-1">
          <div class="mat-4 pad-r-1">
            <mat-label>From<span class="asterisk-mark">*</span></mat-label>
            <mat-form-field floatLabel="never" class="full-width mat-datepicker-alignment">
              <input matInput
                [max]="prescriptionsSearchFormGroup.value.toDateControl? prescriptionsSearchFormGroup.value.toDateControl: maxDate"
                [matDatepicker]="formDatePicker" formControlName="formDateControl" matNativeControl
                placeholder="Enter Date" />
              <mat-datepicker-toggle matIconSuffix [for]="formDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #formDatePicker></mat-datepicker>
            </mat-form-field>
            <app-custom-mat-hint [formGroup]="prescriptionsSearchFormGroup" [isCheckTouch]="checkTouchForForm"
              controlName="formDateControl"></app-custom-mat-hint>
          </div>
          <div class="mat-4 pad-r-1" [ngClass]="{'disabled-date':!prescriptionsSearchFormGroup.value.formDateControl}">
            <mat-label>To<span class="asterisk-mark">*</span></mat-label>
            <mat-form-field floatLabel="never" class="full-width mat-datepicker-alignment">
              <input matInput [disabled]="!prescriptionsSearchFormGroup.value.formDateControl"
                [min]="prescriptionsSearchFormGroup.value.formDateControl" [max]="maxDate" matNativeControl
                [matDatepicker]="toDatePicker" formControlName="toDateControl" placeholder="Enter Date" />
              <mat-datepicker-toggle matIconSuffix [for]="toDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #toDatePicker></mat-datepicker>
            </mat-form-field>
            <app-custom-mat-hint [formGroup]="prescriptionsSearchFormGroup" [isCheckTouch]="checkTouchForForm"
              controlName="toDateControl"></app-custom-mat-hint>
          </div>
          <div class="mat-6">
            <button class="primary-btn top-label-space"
              [disabled]="dateDifferenceValidator() || prescriptionsSearchFormGroup.invalid" (click)="resetSearch()"
              mat-button>
              Search
            </button>
          </div>
        </div>
        <div *ngIf="dateDifferenceValidator()">
          <p style="color: #FF8605">
            The date range cannot be more than 24 months.
          </p>
        </div>
      </form>
    </div>
    <mat-divider></mat-divider>
  </ng-container>
  <div class="filter-block mb-1" *ngIf="isTableDataLoaded">
    <div class="mat-6 mt-4">
      <h4>{{totalLength}} Results Found</h4>
    </div>
    <div class="select-search">
      <div class="search-input-block">
        <img src="../../../../assets/images/search-icon.svg" alt="" />
        <input type="text" class="form-control" [(ngModel)]="searchList" (keyup)="search($event)" placeholder="Search"
          #input />
      </div>
      <div class="select-status" role="status">
        <mat-form-field floatLabel="never" class="full-width">
          <mat-select placeholder="Select Client" id="select-status" aria-labelledby="select-status-label"
            [(ngModel)]="status" class="form-control" (selectionChange)="statusChange()" matNativeControl>
            <ng-container>
              <mat-option value="all" selected>All</mat-option>
              <mat-option *ngFor="let statusVal of statusList" [value]="statusVal">{{ statusVal }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
  </div>
  </div>
  <div class="refill-options mb-1" *ngIf="isTableDataLoaded">
    <div class="mt-2" *ngIf="isTableDataLoaded && clientIdsWithoutAutoChargeAndShip.includes(memberDetails.clientId)">
      <h4><span>Refill Options:</span> <img src="./../../../../assets/images/reminder-icon.svg" height="18">Refill
        Reminder <img src="./../../../../assets/images/ChargeShip-icon.svg">Auto Charge &amp; Ship </h4>
    </div>
  </div>
  <div class="custom-box no-padding hidden custom-height prescriptions-table" *ngIf="isTableDataLoaded">
    <table mat-table [dataSource]="dataSource" matSort>
      <!-- prescribedDrugName Column -->
      <ng-container matColumnDef="prescribedDrugName">
        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
          Drug Name
        </th>
        <td mat-cell *matCellDef="let row" width="280">{{ row.prescribedDrugName }}</td>
      </ng-container>

      <!-- rxNumber Column -->
      <ng-container matColumnDef="rxNumber">
        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
          Rx Number
        </th>
        <td mat-cell *matCellDef="let row">{{ row.rxNumber }}</td>
      </ng-container>

      <!-- patientName Column -->
      <ng-container matColumnDef="patientFirstName">
        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
          Patient Name
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.patientFirstName + " " + row.patientLastName }}
        </td>
      </ng-container>

      <!-- prescriberName Column -->
      <ng-container matColumnDef="prescriberFirstName">
        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
          Prescriber Name
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.prescriberFirstName + " " + row.prescriberLastName }}
        </td>
      </ng-container>

      <!-- quantity Column -->
      <ng-container matColumnDef="quantity">
        <th class="header-center" scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
          Quantity
        </th>
        <td class="center" mat-cell *matCellDef="let row">
          {{ row.quantity }}
        </td>
      </ng-container>

      <!-- daysSupply Column -->
      <ng-container matColumnDef="daysSupply">
        <th class="header-center" scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
          Days Supply
        </th>
        <td class="center" mat-cell *matCellDef="let row">
          {{ row.daysSupply }}
        </td>
      </ng-container>

      <!-- dispensedDate Column -->
      <ng-container matColumnDef="nextRefillDate">
        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
          Refill Date
        </th>
        <td mat-cell *matCellDef="let row">
          {{
          row.nextRefillDate
          ? (row.nextRefillDate | timeStampToDate)
          : "-"
          }}
        </td>
      </ng-container>

      <!-- fillableStatus Column -->
      <ng-container matColumnDef="fillableStatus">
        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
          Status
        </th>
        <td mat-cell *matCellDef="let row" width="110">
          <span class="=text-w-400 status tosoon">{{ row.fillableStatus }}</span>
        </td>
      </ng-container>

      <!-- Refill Column -->
      <ng-container matColumnDef="autoRefill">
        <ng-container *ngIf="clientIdsWithoutAutoChargeAndShip.includes(memberDetails.clientId)">
          <th class="" scope="col" mat-header-cell *matHeaderCellDef>
            Refill Options
          </th>
        </ng-container>
        <ng-container *ngIf="!clientIdsWithoutAutoChargeAndShip.includes(memberDetails.clientId)">
          <th class="" scope="col" mat-header-cell *matHeaderCellDef>
            Refill Reminder
          </th>
        </ng-container>

        <td class="" mat-cell *matCellDef="let row" width="110">
          <div class="flex" fxLayout="row" *ngIf="row.daysSupply > 14" fxLayoutAlign="left" class="relative-action">
            <div *ngIf="row.drugDeaClass !== 2">
              <mat-icon class="reminder-on" matTooltip="Refill Reminder"
                *ngIf="row.autoRefillReminder === 'YES'"></mat-icon>
              <mat-icon class="reminder-off" matTooltip="Refill Reminder"
                *ngIf="row.autoRefillReminder === 'NO'"></mat-icon>
            </div>
            <div
              *ngIf="!drugClassValues.includes(row.drugDeaClass) && row.specialtyIndicator === 'NO' && clientIdsWithoutAutoChargeAndShip.includes(memberDetails.clientId)">
              <mat-icon class="charge-ship-on" matTooltip="Auto Charge & Ship"
                *ngIf="row.autoRefillChargeShip === 'YES'"></mat-icon>
              <mat-icon class="charge-ship-off" matTooltip="Auto Charge & Ship"
                *ngIf="row.autoRefillChargeShip === 'NO'"></mat-icon>
            </div>
          </div>
          <div class="flex" fxLayout="row" *ngIf="row.daysSupply <= 14" fxLayoutAlign="left"
            class="relative-action pl-2">
            <span class="=text-w-400 status tosoon">-</span>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr tabindex="0" (keydown.enter)="$event.stopPropagation(); openDialog(row)" (click)="openDialog(row)" mat-row
        *matRowDef="let row; columns: displayedColumns"></tr>

      <!-- Row shown when there is no matching data. -->
      <ng-container *matNoDataRow>
        <tr class="mat-row" *ngIf="!isLoading && (dataSource.data.length == 0 || totalLength == 0)">
          <td class="mat-cell" colspan="10" role="status">
            No Data found
          </td>
        </tr>
      </ng-container>
    </table>
    <div class="mt-3 mb-3">
      <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" fxLayoutAlign="end center">
      <button class="secondary-btn top-label-space" (click)="navigateBackToMemberSearch()" mat-button>
        Cancel
      </button>
    </div>
  </div>
</div>