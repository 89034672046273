<div class="page-wrapper">
  <!-- <a class="back-arrow-inner" style="cursor: pointer" (click)="navigateBackToMemberSearch()"><mat-icon
      matListIcon>arrow_back</mat-icon> Member Search</a> -->
  <div class="page-header">
    <h2>Add a New Member</h2>
  </div>

  <mat-horizontal-stepper class="add-new-member-stepper" [linear]="isLinear" #stepper>
    <mat-step [stepControl]="personalIfoFormGroup">
      <p class="form-instruction">
        Please complete the member's personal information
      </p>
      <form [formGroup]="personalIfoFormGroup">
        <ng-template matStepLabel>Personal</ng-template>
        <div class="form-wrapper add-new-member-wrapper">
          <div class="mat-row pad-b-1">
            <div class="mat-6 pad-r-2">
              <mat-label>First Name<span class="asterisk-mark">*</span></mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <input matInput placeholder=" Enter First Name" formControlName="firstName" />
              </mat-form-field>
              <app-custom-mat-hint [formGroup]="personalIfoFormGroup" [isCheckTouch]="checkTouchForPersonalInfo"
                controlName="firstName"></app-custom-mat-hint>
            </div>
            <div class="mat-6">
              <mat-label>Gender<span class="asterisk-mark">*</span></mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <mat-select placeholder="Select Gender" formControlName="gender">
                  <mat-option *ngFor="let gender of genderList" [value]="gender.key">{{ gender.name }}</mat-option>
                </mat-select>
              </mat-form-field>
              <app-custom-mat-hint [formGroup]="personalIfoFormGroup" [isCheckTouch]="checkTouchForPersonalInfo"
                controlName="gender"></app-custom-mat-hint>
            </div>
          </div>
          <div class="mat-row pad-b-1">
            <div class="mat-6 pad-r-2">
              <mat-label>Middle Name</mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <input matInput placeholder="Enter Middle Name" formControlName="middleName" />
              </mat-form-field>
              <app-custom-mat-hint [formGroup]="personalIfoFormGroup" [isCheckTouch]="checkTouchForPersonalInfo"
                controlName="middleName"></app-custom-mat-hint>
            </div>
            <div class="mat-6">
              <mat-label>DOB<span class="asterisk-mark">*</span></mat-label>
              <mat-form-field floatLabel="never" class="full-width mat-datepicker-alignment">
                <input matInput [max]="maxDate" [matDatepicker]="picker" formControlName="dob"
                  placeholder="Enter DOB" />
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <app-custom-mat-hint [formGroup]="personalIfoFormGroup" [isCheckTouch]="checkTouchForPersonalInfo"
                controlName="dob"></app-custom-mat-hint>
            </div>
          </div>
          <div class="mat-row pad-b-1">
            <div class="mat-6 pad-r-2">
              <mat-label>Last Name<span class="asterisk-mark">*</span></mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <input matInput placeholder="Enter Last Name" formControlName="lastName" />
              </mat-form-field>
              <app-custom-mat-hint [formGroup]="personalIfoFormGroup" [isCheckTouch]="checkTouchForPersonalInfo"
                controlName="lastName"></app-custom-mat-hint>
            </div>
            <div class="mat-6">
              <mat-label>SSN</mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <input matInput appSsnFormat type="text" placeholder="Enter SSN (999-99-9999)" formControlName="ssn"
                  (keydown)="numericOnly($event)" />
              </mat-form-field>
              <app-custom-mat-hint [formGroup]="personalIfoFormGroup" [isCheckTouch]="checkTouchForPersonalInfo"
                controlName="ssn"></app-custom-mat-hint>
            </div>
          </div>
        </div>
        <div class="mat-row button-row">
          <div class="mat-12" fxLayoutAlign="end end">
            <button class="primary-btn" mat-button matStepperNext (click)="checkTouchForPersonalInfo = true">
              Next
            </button>
            <button mat-button matStepperPrevious class="secondary-btn" (click)="cancel()">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="benefitFormGroup">
      <form [formGroup]="benefitFormGroup">
        <ng-template matStepLabel>Benefit</ng-template>
        <p class="form-instruction">
          Please complete the member's benefit information
        </p>
        <div class="form-wrapper add-new-member-wrapper">
          <div class="mat-row pad-b-1">
            <div class="mat-12">
              <mat-label>Client<span class="asterisk-mark">*</span></mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <mat-select formControlName="client" placeholder="Select Client" panelClass="custom-select-panel"
                  (selectionChange)="onClientSelection($event)" [disableOptionCentering]="true">
                  <mat-option>
                    <ngx-mat-select-search [formControl]="clientFilterControl" [clearSearchInput]="true"
                      [showToggleAllCheckbox]="false" placeholderLabel="Search Client(s) by ID or Name..."
                      noItemsLabel="No clients found">
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngIf="(filteredClients | async)?.length === 0" disabled>
                    No clients found
                  </mat-option>
                  <mat-option *ngFor="let client of filteredClients | async" [value]="client.clientId">
                    {{ client.clientId }} - {{ client.clientName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <app-custom-mat-hint [formGroup]="benefitFormGroup" [isCheckTouch]="checkTouchForBenefitInfo"
                controlName="client"></app-custom-mat-hint>
            </div>
          </div>
          <div class="mat-row pad-b-1">
            <div class="mat-12">
              <mat-label>Group<span class="asterisk-mark">*</span></mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <mat-select placeholder="Select Group" (selectionChange)="onGroupSelection($event)"
                  formControlName="group" panelClass="custom-select-panel" [disableOptionCentering]="true">
                  <mat-option>
                    <ngx-mat-select-search [formControl]="groupFilterControl" [clearSearchInput]="true"
                      [showToggleAllCheckbox]="false" placeholderLabel="Search Group(s) by ID or Name..."
                      noItemsLabel="No group found">
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngIf="(filteredGroups | async)?.length === 0" disabled>
                    No groups found
                  </mat-option>
                  <ng-container *ngIf="filteredGroups | async as groups">
                    <mat-option class="grp-dropdown" *ngFor="let group of filteredGroups| async as groups"
                      [value]="group.groupId">
                      {{ group.groupId }}: {{group.groupName }}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
              <mat-hint class="text-danger title-11" *ngIf="groupList?.length === 0">
                No groups found for this client.
              </mat-hint>
              <app-custom-mat-hint [formGroup]="benefitFormGroup" [isCheckTouch]="checkTouchForBenefitInfo"
                controlName="group"></app-custom-mat-hint>
            </div>
          </div>
          <div class="mat-row pad-b-1" fxLayout="row">
            <!-- <div class="mat-6 pad-r-2">
              <mat-label
                >EmpiRx Member ID<span class="asterisk-mark">*</span></mat-label
              >
              <mat-form-field floatLabel="never" class="full-width">
                <input
                  type="text"
                  matInput
                  placeholder="Enter EmpiRx Member ID"
                  formControlName="empiRxMemberId"
                />
              </mat-form-field>
              <app-custom-mat-hint
                [formGroup]="benefitFormGroup"
                [isCheckTouch]="checkTouchForBenefitInfo"
                controlName="empiRxMemberId"
              ></app-custom-mat-hint>
            </div> -->
            <div class="mat-6 pad-r-2">
              <mat-label>Coverage Effective<span class="asterisk-mark">*</span></mat-label>
              <mat-form-field floatLabel="never" class="full-width mat-datepicker-alignment">
                <input matInput [matDatepicker]="picker1" formControlName="coverageEffect" placeholder="MM/DD/YYYY" />
                <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
              <app-custom-mat-hint [formGroup]="benefitFormGroup" [isCheckTouch]="checkTouchForBenefitInfo"
                controlName="coverageEffect"></app-custom-mat-hint>
            </div>
            <div class="mat-6">
              <mat-label>Coverage Type<span class="asterisk-mark">*</span></mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <mat-select matNativeControl placeholder="Select Coverage Type" formControlName="coverageType">
                  <mat-option value="FAM"> Family </mat-option>
                  <mat-option value="IND">Individual</mat-option>
                </mat-select>
              </mat-form-field>
              <app-custom-mat-hint [formGroup]="benefitFormGroup" [isCheckTouch]="checkTouchForBenefitInfo"
                controlName="coverageType"></app-custom-mat-hint>
            </div>
          </div>

          <div class="mat-row pad-b-1" fxLayout="row">
            <div class="mat-6 pad-r-2" *ngIf="isLocationCodeRequired">
              <mat-label>Client Internal Member ID <span class="asterisk-mark">*</span></mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <mat-select matNativeControl placeholder="Select Client Internal Member ID"
                  formControlName="clientInternalMemberId">
                  <ng-container>
                    <mat-option *ngFor="let locCode of clientLocationCode" [value]="locCode.value">
                      {{ locCode.displayName }}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
              <mat-hint class="text-danger title-11" *ngIf="clientLocationCode?.length === 0">
                No Client Internal Member IDs found.
              </mat-hint>
              <app-custom-mat-hint [formGroup]="benefitFormGroup" [isCheckTouch]="checkTouchForBenefitInfo"
                controlName="clientInternalMemberId"></app-custom-mat-hint>
            </div>
            <ng-container *ngIf="selectedProductIndicator === 'Member Guide'">
              <div class="mat-6">
                <mat-label class="radio-label">Order ID Card<span class="asterisk-mark">*</span></mat-label>
                <mat-radio-group floatLabel="never" class="full-width" formControlName="orderIdCard">
                  <mat-radio-button value="1">Yes</mat-radio-button>
                  <mat-radio-button value="0">No</mat-radio-button>
                </mat-radio-group>
                <app-custom-mat-hint [formGroup]="benefitFormGroup" [isCheckTouch]="checkTouchForBenefitInfo"
                  controlName="orderIdCard"></app-custom-mat-hint>
              </div>
            </ng-container>
          </div>

          <div class="mat-row pad-b-1" fxLayout="row">
            <ng-container *ngIf="isAutoGenIdCard$ | async as isIdAutoGenerated">
              <div class="mat-6 pad-r-2" *ngIf="!isIdAutoGenerated.autoGenerateCardId">
                <mat-label>Card ID<span class="asterisk-mark">*</span></mat-label>
                <mat-form-field floatLabel="never" class="full-width">
                  <input type="text" matInput placeholder="Enter Card ID" formControlName="cardId" />
                </mat-form-field>
                <app-custom-mat-hint [formGroup]="benefitFormGroup" [isCheckTouch]="checkTouchForBenefitInfo"
                  controlName="cardId"></app-custom-mat-hint>
                <mat-hint class="text-danger title-11" *ngIf="benefitFormGroup.value.client === '19121'">
                  Please do not enter UNE in the Card ID field. Enter the
                  characters that appear after UNE.
                </mat-hint>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="mat-row button-row" fxLayout="row">
          <div class="mat-6">
            <button mat-button matStepperPrevious class="secondary-btn">
              Back
            </button>
          </div>
          <div class="mat-6" fxLayoutAlign="end end">
            <button mat-button matStepperNext class="primary-btn" (click)="checkTouchForBenefitInfo = true">
              Next
            </button>
            <button mat-button matStepperPrevious class="secondary-btn" (click)="cancel()">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="addressFormGroup">
      <ng-template matStepLabel>Address</ng-template>
      <form [formGroup]="addressFormGroup">
        <ng-template matStepLabel>Address</ng-template>
        <p class="form-instruction">
          Please complete the member's address information
        </p>
        <div class="form-wrapper add-new-member-wrapper">
          <div class="mat-row pad-b-1" fxLayout="row">
            <div class="mat-6 pad-r-2">
              <mat-label>Address Line 1<span class="asterisk-mark">*</span></mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <input matInput placeholder="Enter Address Line 1" formControlName="addressLine1" />
              </mat-form-field>
              <app-custom-mat-hint [formGroup]="addressFormGroup" [isCheckTouch]="checkTouchForAddressInfo"
                controlName="addressLine1"></app-custom-mat-hint>
            </div>
            <div class="mat-6">
              <mat-label>Zip Code<span class="asterisk-mark">*</span></mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <input type="text" matInput placeholder="Enter Zip Code" (keydown)="numericOnly($event)"
                  formControlName="zipCode" />
              </mat-form-field>
              <app-custom-mat-hint [formGroup]="addressFormGroup" [isCheckTouch]="checkTouchForAddressInfo"
                controlName="zipCode"></app-custom-mat-hint>
            </div>
          </div>
          <div class="mat-row pad-b-1" fxLayout="row">
            <div class="mat-6 pad-r-2">
              <mat-label>Address Line 2</mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <input matInput placeholder="Enter Address Line 2" formControlName="addressLine2" />
              </mat-form-field>
              <app-custom-mat-hint [formGroup]="addressFormGroup" [isCheckTouch]="checkTouchForAddressInfo"
                controlName="addressLine2"></app-custom-mat-hint>
            </div>
            <div class="mat-4 pad-r-2">
              <mat-label>State<span class="asterisk-mark">*</span></mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <mat-select matNativeControl placeholder="Select State" formControlName="state"
                  panelClass="custom-select-panel" [disableOptionCentering]="true">
                  <mat-option>
                    <ngx-mat-select-search [formControl]="stateFilterControl" [clearSearchInput]="true"
                      [showToggleAllCheckbox]="false" placeholderLabel="Search state by Name..."
                      noItemsLabel="No states found">
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngIf="(filteredStates | async)?.length === 0" disabled>
                    No states found
                  </mat-option>

                  <mat-option *ngFor="let state of filteredStates| async as States" [value]="state.value">
                    {{state.name}}
                  </mat-option>

                </mat-select>
              </mat-form-field>
              <app-custom-mat-hint [formGroup]="addressFormGroup" [isCheckTouch]="checkTouchForAddressInfo"
                controlName="state"></app-custom-mat-hint>
            </div>
            <div class="mat-4">
              <mat-label>City<span class="asterisk-mark">*</span></mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <input matInput (keydown)="charOnlySingleSpace($event)" placeholder="Enter City"
                  formControlName="city" />
              </mat-form-field>
              <app-custom-mat-hint [formGroup]="addressFormGroup" [isCheckTouch]="checkTouchForAddressInfo"
                controlName="city"></app-custom-mat-hint>
            </div>
          </div>
        </div>
        <div class="mat-row button-row" fxLayout="row">
          <div class="mat-6">
            <button mat-button matStepperPrevious class="secondary-btn">
              Back
            </button>
          </div>
          <div class="mat-6" fxLayoutAlign="end end">
            <button mat-button matStepperNext class="primary-btn" (click)="checkTouchForAddressInfo = true">
              Next
            </button>
            <button mat-button matStepperPrevious class="secondary-btn" (click)="cancel()">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="contactInfoFormGroup">
      <form [formGroup]="contactInfoFormGroup">
        <ng-template matStepLabel>Contact</ng-template>
        <p class="form-instruction">
          Please complete the member's contact information
        </p>
        <div class="form-wrapper add-new-member-wrapper">
          <h4>Phone Number</h4>
          <div class="mat-row pad-b-1" fxLayout="row">
            <div class="mat-6 pad-r-2">
              <mat-label>Type</mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <mat-select matNativeControl placeholder="Select Phone Type" formControlName="phoneType">
                  <mat-option *ngFor="let type of phoneTypes" [value]="type.value">{{ type.name }}</mat-option>
                </mat-select>
              </mat-form-field>
              <app-custom-mat-hint [formGroup]="contactInfoFormGroup" [isCheckTouch]="checkTouchForContactInfo"
                controlName="phoneType"></app-custom-mat-hint>
            </div>
            <div class="mat-6">
              <mat-label>Phone Number</mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <input type="text" matInput appPhoneNumberFormat placeholder="Enter Number (555-555-5555)"
                  (keydown)="numericOnly($event)" formControlName="phoneNumber" />
              </mat-form-field>
              <app-custom-mat-hint [formGroup]="contactInfoFormGroup" [isCheckTouch]="checkTouchForContactInfo"
                controlName="phoneNumber"></app-custom-mat-hint>
            </div>
          </div>
          <!-- <mat-form-field>
            <mat-label>Area Code</mat-label>
            <input matInput placeholder="Enter Area Code" formControlName="areaCode" required>
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>Extension</mat-label>
            <input matInput placeholder="Enter Extension" formControlName="extension" required>
          </mat-form-field>
          <br /> -->

          <h4>Email</h4>
          <!-- <div class="mat-12">
            <mat-form-field class="full-width">
              <mat-label>Type</mat-label>
              <select matNativeControl placeholder="Select Address Type" formControlName="emailType" required>
                <mat-option value="">Select</mat-option>
                <mat-option value="Personal">Personal</mat-option>
                <mat-option value="Official">Official</mat-option>
              </select>
            </mat-form-field>
          </div> -->
          <div class="mat-row pad-b-1">
            <div class="mat-6 pad-r-2">
              <mat-label>Email ID</mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <input matInput placeholder="Enter Email" formControlName="email" />
              </mat-form-field>
              <app-custom-mat-hint [formGroup]="contactInfoFormGroup" [isCheckTouch]="checkTouchForContactInfo"
                controlName="email"></app-custom-mat-hint>
            </div>
          </div>
        </div>
        <div class="mat-row button-row" fxLayout="row">
          <div class="mat-6">
            <button mat-button class="secondary-btn" matStepperPrevious>
              Back
            </button>
          </div>
          <div class="mat-6" fxLayoutAlign="end end">
            <button mat-button [disabled]="contactInfoFormGroup.invalid" class="primary-btn create-member"
              (click)="createMember()">
              Create Member
            </button>
            <button mat-button class="secondary-btn" (click)="cancel()">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </mat-step>
  </mat-horizontal-stepper>
</div>