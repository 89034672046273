<h4 class="modal-header prescription-header">
    Prescription Details
    <span class="close-icon-dialog"><mat-icon (click)="cancel()">close</mat-icon></span>
</h4>
<ng-container>
    <!-- Member Detail card -->
    <div class="detail-card">
        <div>
            <h4>Patient Name</h4>
            <p>{{prescription.patientFirstName}} {{prescription.patientLastName}}</p>
        </div>
        <div>
            <h4>Quantity</h4>
            <p>{{prescription.quantity ? prescription.quantity : '-'}}</p>
        </div>
        <div>
            <h4>No of Refills Allowed</h4>
            <p>{{prescription.numberOfRefillsAllowed ? prescription.numberOfRefillsAllowed : '-'}}</p>
        </div>
        <div>
            <h4>Patient DOB</h4>
            <p>{{prescription.patientDateOfBirth? (prescription.patientDateOfBirth | timeStampToDate): '-'}}</p>
        </div>
        <div>
            <h4>Days Supply</h4>
            <p>{{prescription.daysSupply ? prescription.daysSupply : '-'}}</p>
        </div>
        <div>
            <h4>Number of Refills Remaining</h4>
            <p>{{prescription.numberOfRefillsRemaining ? prescription.numberOfRefillsRemaining : '-'}}</p>
        </div>
        <div>
            <h4>Rx Number</h4>
            <p>{{prescription.rxNumber ? prescription.rxNumber : '-'}}</p>
        </div>
        <div>
            <h4>Dispensed Date</h4>
            <p>{{prescription.dispensedDate? (prescription.dispensedDate | timeStampToDate) : '-'}}</p>
        </div>
        <div>
            <h4>Next Refill Date</h4>
            <p>{{prescription.nextRefillDate ? (prescription.nextRefillDate | timeStampToDate) : '-'}} &nbsp;</p>
        </div>
        <div>
            <h4>Prescribed Drug Name</h4>
            <p>{{prescription.prescribedDrugName ? prescription.prescribedDrugName : '-'}}</p>
        </div>
        <div>
            <h4>Prescriber Name</h4>
            <p>{{prescription.prescriberFirstName}} {{prescription.prescriberLastName}}</p>
        </div>
        <div>
            <h4>Fillable Status</h4>
            <p>{{prescription.fillableStatus ? prescription.fillableStatus : '-'}}</p>
        </div>
    </div>
    <div class="detail-card">
        <div>
            <h4>Dispensed Drug Name</h4>
            <p>{{prescription.dispensedDrugName ? prescription.dispensedDrugName : '-'}}</p>
        </div>
        <div>
            <h4>Directions</h4>
            <p>{{prescription.directions ? prescription.directions : '-'}}</p>
        </div>
        <div>
            <h4>Specialty Drug</h4>
            <p>{{prescription.specialtyIndicator ? prescription.specialtyIndicator : '-'}}</p>
        </div>
    </div>
    <div class="detail-card">
        <div>
            <h4>DAW - List of Values</h4>
            <p>{{prescription.daw ? prescription.daw : '-'}}</p>
        </div>
        <div>
            <h4>Rx Date</h4>
            <p>{{prescription.rxDate? (prescription.rxDate | timeStampToDate): '-'}}</p>
        </div>
        <div *ngIf="prescription.daysSupply && prescription.daysSupply >= 14">
            <h4>Refill Reminder</h4>
            <p>{{prescription.autoRefillReminder? prescription.autoRefillReminder: '-'}}</p>
        </div>
    </div>
    <div class="detail-card">
        <div>
            <h4>Drug DEA Class</h4>
            <p>{{prescription.drugDeaClass ? prescription.drugDeaClass : '-'}}</p>
        </div>
        <div>
            <h4>Expiration Date</h4>
            <p>{{prescription.expirationDate? (prescription.expirationDate| timeStampToDate) : '-'}}</p>
        </div>
        <div *ngIf="dialogData.clientIdsWithoutAutoChargeAndShip.includes(dialogData.memberDetails.clientId) && prescription.daysSupply && prescription.daysSupply >= 14">
            <h4>Auto Charge & Ship</h4>
            <p>{{prescription.autoRefillChargeShip? prescription.autoRefillChargeShip: '-'}}</p>
        </div>
    </div>
</ng-container>