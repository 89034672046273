import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppConstants } from 'src/app/app.constants';
import { AuditLogsService } from 'src/app/core/services/audit-logs/audit-logs.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { SaveAuditLogsRequestInterface } from 'src/app/interfaces/audit-logs.interface';
import {
  Coverage,
  IUpdateContactFormGroupValue,
  MemberDetails,
} from 'src/app/interfaces/member.interface';
import {
  clearPhoneFormat,
  numericOnly as externalNumericOnly,
  formatPhone,
  getErrorMessage,
} from 'src/app/util';
import { EligibilityService } from '../../../../core/services/eligibility/eligibility.service';
import { LoaderService } from '../../../services/loader/loader.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ClientInfoResponse } from 'src/app/interfaces/UserGroup.interface';
import { ErrorManagerService, ErrorType } from '../../../../core/services/error';
import { HttpErrorResponse } from '@angular/common/http';
import { AppCenterAnalytics } from '../../../../services/app-insights/app-insights';
import { AppInsightsService } from '../../../../services/app-insights/app-insights.service';

@Component({
  selector: 'app-update-contact-info-dialog',
  templateUrl: './update-contact-info-dialog.component.html',
  styleUrls: ['./update-contact-info-dialog.component.scss'],
})
export class UpdateContactInfoDialogComponent implements OnInit {
  public types: { value: string; name: string }[] = [
    { value: 'homePhone', name: 'Home Phone' },
    { value: 'cellPhone', name: 'Cell Phone' },
    { value: 'workPhone', name: 'Work Phone' },
  ];
  public updateContactEmailInfoFormGroup: FormGroup;
  private initialUpdateContactFormValues: IUpdateContactFormGroupValue;

  public contactDetails: IUpdateContactFormGroupValue = {
    phoneType: '',
    phoneNumber: '',
    email: '',
  };
  numericOnly = externalNumericOnly;
  dataSaved: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  groupInfo: { groupId: string; groupName: string } = {
    groupId: '',
    groupName: '',
  };
  groupInfo$: Observable<{ groupId: string; groupName: string }> =
    new Observable();

  constructor(
    public dialogRef: MatDialogRef<UpdateContactInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private readonly formBuilder: FormBuilder,
    private auditLogsService: AuditLogsService,
    private eligibilityService: EligibilityService,
    private authService: AuthService,
    private loader: LoaderService,
    private errorManager: ErrorManagerService,
    private readonly appInsightsService: AppInsightsService,
  ) { }

  ngOnInit(): void {
    const emailValidator = Validators.pattern(
      AppConstants.EMAIL_VALIDATION_PATTERN
    );
    this.updateContactEmailInfoFormGroup = this.formBuilder.group({
      phoneType: [''],
      phoneNumber: [''],
      email: ['', [emailValidator]],
    });
    this.contactDetails.phoneNumber = this.dialogData.phoneDetails?.phoneNumber
      ? this.formatPhoneNumber(this.dialogData.phoneDetails?.phoneNumber)
      : '';
    this.contactDetails.phoneType = this.dialogData.phoneDetails?.type
      ? this.dialogData.phoneDetails?.type
      : '';
    this.contactDetails.email = this.dialogData.memberDetails?.email
      ? this.dialogData.memberDetails?.email
      : '';

    if (this.dialogData.edit) {
      this.populateMemberDetails(this.contactDetails);
    }
    this.initialUpdateContactFormValues = {
      ...this.updateContactEmailInfoFormGroup.value,
    };

    if (this.dialogData.type === 'email') {
      this.setEmailValidator();
      this.removePhoneValidator();
      // console.log('Type mail', this.updateContactEmailInfoFormGroup);
    } else if (this.dialogData.type === 'contact') {
      // console.log('Type phone', this.updateContactEmailInfoFormGroup);
      this.setPhoneValidator();
      this.removeEmailValidator();
    }

    this.groupInfo$ = this.eligibilityService
      .getGroupInfo(
        this.dialogData.memberDetails.clientId,
        this.dialogData.memberDetails.coverage.groupNumber
      )
      .pipe(
        tap((res) => {
          // console.log('group Info', res);
          this.groupInfo = res;
        })
      );
  }

  checkFormValidation() { }

  onClientSelection(event) {
    this.updateContactEmailInfoFormGroup.controls.phoneNumber.reset();
    if (this.getPhoneNumber(event.value) !== 'null') {
      this.updateContactEmailInfoFormGroup.controls.phoneNumber.setValue(
        this.formatPhoneNumber(this.getPhoneNumber(event.value))
      );
    }
  }

  formatPhoneNumber(phone: string): string {
    return formatPhone(phone);
  }

  public getPhoneNumber(phoneType: string): string {
    return this.dialogData.memberDetails.phones.filter(
      (p) => p.type === phoneType
    )[0].phoneNumber;
  }

  setPhoneValidator() {
    this.updateContactEmailInfoFormGroup
      .get('phoneType')
      .setValidators([Validators.required]);
    this.updateContactEmailInfoFormGroup
      .get('phoneType')
      .updateValueAndValidity();
    this.updateContactEmailInfoFormGroup
      .get('phoneNumber')
      .setValidators([Validators.required, Validators.minLength(10)]);
    this.updateContactEmailInfoFormGroup
      .get('phoneNumber')
      .updateValueAndValidity();
  }
  setEmailValidator() {
    this.updateContactEmailInfoFormGroup
      .get('email')
      .setValidators([
        Validators.required,
        Validators.pattern(AppConstants.EMAIL_VALIDATION_PATTERN),
      ]);
    this.updateContactEmailInfoFormGroup.get('email').updateValueAndValidity();
  }

  removePhoneValidator() {
    this.updateContactEmailInfoFormGroup.get('phoneNumber').clearValidators();
    this.updateContactEmailInfoFormGroup
      .get('phoneNumber')
      .updateValueAndValidity();
    this.updateContactEmailInfoFormGroup.get('phoneType').clearValidators();
    this.updateContactEmailInfoFormGroup
      .get('phoneType')
      .updateValueAndValidity();
  }

  removeEmailValidator() {
    this.updateContactEmailInfoFormGroup.get('email').clearValidators();
    this.updateContactEmailInfoFormGroup.get('email').updateValueAndValidity();
  }
  private populateMemberDetails(memberDetails: any) {
    this.updateContactEmailInfoFormGroup.setValue(memberDetails);
  }
  public checkIfAnythingUpdated(): boolean {
    let formCopy = this.updateContactEmailInfoFormGroup.value;
    formCopy.phoneNumber = this.formatPhoneNumber(formCopy.phoneNumber);

    return (
      JSON.stringify(formCopy) !==
      JSON.stringify(this.initialUpdateContactFormValues)
    );
  }
  public save(): void {
    const reqObj: MemberDetails = {
      patientId: this.dialogData.memberDetails.patientId,
      firstName: this.dialogData.memberDetails.firstName,
      lastName: this.dialogData.memberDetails.lastName,
      middleName: this.dialogData.memberDetails.middleName,
      DOB: this.dialogData.memberDetails.DOB,
      clientId: this.dialogData.memberDetails.clientId,
      gender: this.dialogData.memberDetails.gender,
      email: this.dialogData.memberDetails.email,
      addressLine1: this.dialogData.memberDetails.addressLine1.trim(),
      addressLine2: this.dialogData.memberDetails.addressLine2.trim(),
      zipCode: this.dialogData.memberDetails.zipCode,
      city: this.dialogData.memberDetails.city.trim(),
      state: this.dialogData.memberDetails.state,
      effectiveDate: this.dialogData.memberDetails.coverage?.startDate,
      terminationDate: this.dialogData.memberDetails.coverage?.endDate,
      groupNumber: this.dialogData.memberDetails.coverage?.groupNumber,
      isUpdated: true,
      homePhone: this.getPhoneNumber('homePhone'),
      cellPhone: this.getPhoneNumber('cellPhone'),
      workPhone: this.getPhoneNumber('workPhone'),
      personCode: this.dialogData.memberDetails.personCode,
      relationshipCode: this.dialogData.memberDetails.relationshipCode,
      cardId: this.dialogData.memberDetails.cardId,
      coverageType: this.dialogData.memberDetails.coverage?.coverageType,
      SSN: this.dialogData.memberDetails.SSN,
      flexTagName: this.dialogData.memberDetails.flexTag.flexTagName,
      flexTagStartDate: this.dialogData.memberDetails.flexTag.flexTagStartDate,
      flexTagEndDate: this.dialogData.memberDetails.flexTag.flexTagEndDate,
      flexTagName2: this.dialogData.memberDetails.flexTag2.flexTagName,
      flexTagStartDate2: this.dialogData.memberDetails.flexTag2.flexTagStartDate,
      flexTagEndDate2: this.dialogData.memberDetails.flexTag2.flexTagEndDate,
      flexTagName3: this.dialogData.memberDetails.flexTag3.flexTagName,
      flexTagStartDate3: this.dialogData.memberDetails.flexTag3.flexTagStartDate,
      flexTagEndDate3: this.dialogData.memberDetails.flexTag3.flexTagEndDate,
      flexTagName4: this.dialogData.memberDetails.flexTag4.flexTagName,
      flexTagStartDate4: this.dialogData.memberDetails.flexTag4.flexTagStartDate,
      flexTagEndDate4: this.dialogData.memberDetails.flexTag4.flexTagEndDate,
      flexTagName5: this.dialogData.memberDetails.flexTag5.flexTagName,
      flexTagStartDate5: this.dialogData.memberDetails.flexTag5.flexTagStartDate,
      flexTagEndDate5: this.dialogData.memberDetails.flexTag5.flexTagEndDate,
      clientInternalMemberId: this.dialogData.memberDetails.clientInternalMemberId
    };
    if (this.dialogData.type === 'email') {
      reqObj.email = this.updateContactEmailInfoFormGroup.value.email;
    } else if (this.dialogData.type === 'contact') {
      reqObj[this.updateContactEmailInfoFormGroup.value.phoneType] =
        clearPhoneFormat(
          this.updateContactEmailInfoFormGroup.value.phoneNumber
        );
    }
    this.loader.showLoader();
    this.loader.showWaitingMessage$.next(AppConstants.ERROR.WAIT_MESSAGE_FOR_UPDATE_MEMBER);
    this.eligibilityService.addMember(reqObj).subscribe({
      next: (res) => {
        if (res) {
          this.loader.hideLoader();
          this.loader.showWaitingMessage$.next(null);
          this.dataSaved.emit(true);
          this.dialogRef.close();
          this.createAuditLog(true);
        }
        // console.log('Response on create audit log:', res);
      },
      error: (err) => {
        this.createAuditLog(false, err);
        const message = getErrorMessage(err);
        this.errorManager.throwError({
          type: ErrorType.ERROR_GENERIC,
          message: message,
          component: 'Update contact Info',
          title: '',
        });
        this.loader.hideLoader();
        this.loader.showWaitingMessage$.next(null);
        // console.log('Error on create audit log:', err);
      },
      complete: () => {
        this.loader.hideLoader();
      }
    });
  }

  createAuditLog(success: boolean, err?: HttpErrorResponse) {
    const reqBody: SaveAuditLogsRequestInterface = {
      clientId: this.dialogData.memberDetails.clientId,
      cardId: this.dialogData.memberDetails.cardId,
      personCd: this.dialogData.memberDetails.personCode,
      firstName: this.dialogData.memberDetails.firstName,
      lastName: this.dialogData.memberDetails.lastName,
      emailId:
        this.dialogData.type === 'email'
          ? this.updateContactEmailInfoFormGroup.value.email
          : this.dialogData.memberDetails.email && this.dialogData.memberDetails.email.toLowerCase() !== 'null'
            ? this.dialogData.memberDetails.email
            : this.authService.userEmail,
      birthDt: this.dialogData.memberDetails.DOB,
      groupNo: this.dialogData.memberDetails.coverage?.groupNumber,
      groupName: this.groupInfo.groupName,
      changes:
        this.dialogData.type === 'email'
          ? AppConstants.AUDIT_LOGS.addContactInfo.action.updateEmail.change
          : this.contactDetails.phoneNumber
            ? AppConstants.AUDIT_LOGS.addContactInfo.action.updatePhone.change
            : AppConstants.AUDIT_LOGS.addContactInfo.action.addNewPhone.change,
      comments:
        this.dialogData.type === 'email'
          ? AppConstants.AUDIT_LOGS.addContactInfo.action.updateEmail.comments
          : this.contactDetails.phoneNumber
            ? AppConstants.AUDIT_LOGS.addContactInfo.action.updatePhone.comments
            : AppConstants.AUDIT_LOGS.addContactInfo.action.addNewPhone.comments,
      addedBy: this.authService.userEmail,
      isSuccess: success
    };
    if (!success) {
      const message = err?.error?.Message ? err.error.Message : JSON.stringify(err);
      reqBody.httpStatusCode = err.status,
        reqBody.errorMsg = message
    }
    // console.log('Request Body create audit log:', reqBody);
    this.auditLogsService.saveAuditLog(reqBody).subscribe({
      next: (res) => {
        this.appInsightsService.trackPageView(AppCenterAnalytics.SAVE_AUDIT_LOG_API_SUCCESS, { Response: res });
        // console.log('Response on create audit log:', res);
      },
      error: (err) => {
        this.appInsightsService.trackPageView(AppCenterAnalytics.SAVE_AUDIT_LOG_API_ERROR, { Response: err });
        this.loader.hideLoader();
        // console.log('Error on create audit log:', err);
      },
      complete: () => {
        this.loader.hideLoader();
      },
    });
  }
  public cancel(): void {
    this.dialogRef.close();
  }
}
