import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  Coverage,
  MemberDetails,
  MemberSearchRequest,
} from 'src/app/interfaces/member.interface';
import { Observable, Subscription, defer, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  tap,
} from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { AppConstants } from 'src/app/app.constants';
import { UtilService } from 'src/app/services/util/util.service';
import { EligibilityMenu } from 'src/app/interfaces/menu.interface';
import { Clients } from 'src/app/interfaces/clients.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MatPaginator } from '@angular/material/paginator';
import { EligibilityService } from 'src/app/core/services/eligibility/eligibility.service';
import {
  EligibilityMenus,
  ELIGIBILITY_MENUS,
} from 'src/app/shared/constants/eligibility-menus';
import { BenefitManagementService } from 'src/app/core/services/benefit-management/benefit-management.service';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';
import { AppCenterAnalytics } from 'src/app/services/app-insights/app-insights';
import { LoaderService } from '../services/loader/loader.service';
import { URLSearchParams } from 'url';
import { DemoClientService } from 'src/app/core/services/demo-client/demo-client.service';
import {
  clearSsnFormat,
  formatPhone,
  formatSSN,
  trimIfUNE,
} from 'src/app/util';
import { SharedService } from '../../services/shared/shared.service';
import { UserAccessLevel } from '../../interfaces/user-access.interface';
import { AuthService } from '../../core/services/auth/auth.service';
import { GroupLevelAccessResponse } from '../../interfaces/group-level-access.interface';

@Component({
  selector: 'app-member-search',
  templateUrl: './member-search.component.html',
  styleUrls: ['./member-search.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class MemberSearchComponent implements OnInit, OnDestroy {
  public displayedColumns: string[] = [
    'personCode',
    'name',
    'DOB',
    'cardId',
    'relationShipCode',
    'clientInternalMemberId',
    'menuOptions',
    'expandIcon',
  ];
  public coverageTableColumns: string[] = [
    'group',
    'client',
    'effectiveDate',
    'endDate',
  ];

  public memberMenus: EligibilityMenu[] = [];
  public checkTouchForForm = false;
  public dataSource!: MatTableDataSource<MemberDetails>;
  public members$!: Observable<MemberDetails[]>;
  public listOfClients$: Observable<Array<Clients>>;
  public displayGender = AppConstants.DISPLAY_GENDER;
  public clientList: Clients[] = [];
  public clientIdsWithoutDigitalIdCard: string[] = [];
  public relationShip = AppConstants.RELATIONSHIP_VALUES;

  allFieldsEmpty: boolean = true;

  cardHolderRC: string = AppConstants.RELATIONSHIP_CODES_ALL.CARDHOLDER;

  public idTypes: string[] = [
    'Card/Alt ID',
    'SSN',
    'Client Internal Member ID',
    // 'Patient ID',
  ];

  public searchMemberFormGroup: FormGroup;
  public hasAccessToAddMember: boolean;
  public allRowsExpanded: boolean = false;
  public expandedElement: MemberDetails | null;
  dataLoaded: boolean = false;
  private subscription: Subscription[] = [];
  public groupLevelAccess: GroupLevelAccessResponse[] = [];
  public showAddMember: boolean = false;
  public isGroupLevelAccess: boolean;
  @ViewChild('paginator') paginator: MatPaginator;

  constructor(
    private readonly router: Router,
    private readonly utilService: UtilService,
    private readonly formBuilder: FormBuilder,
    public eligibilityService: EligibilityService,
    private benefitService: BenefitManagementService,
    private readonly appInsightService: AppInsightsService,
    private readonly loader: LoaderService,
    private demoClientService: DemoClientService,
    private sharedService: SharedService,
    private readonly authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.subscription.push(this.sharedService.getClientsWithoutDigitalIDCard().subscribe((res) => {
      this.clientIdsWithoutDigitalIdCard = res[0].split(',').map((id) => id.trim());
    }
    ));
    this.appInsightService.trackPageView(AppCenterAnalytics.ONLINE_ELIGIBILITY);
    this.searchMemberFormGroup = this.formBuilder.group({
      client: ['', [Validators.required]],
      idType: ['', [Validators.required]],
      idValue: [
        '',
        [
          Validators.required,
          Validators.maxLength(40),
          Validators.pattern('^[a-zA-Z0-9-]*$'),
        ],
      ],
      lastName: ['', [Validators.maxLength(35)]],
      firstName: ['', [Validators.maxLength(35)]],
    });

    this.searchMemberFormGroup.patchValue(
      this.eligibilityService.searchCriteriaCache
    );

    if (this.searchMemberFormGroup.value.idType === 'SSN') {
      this.searchMemberFormGroup.controls.idValue.setValue(this.formatSSNString(this.searchMemberFormGroup.value.idValue));
    }

    this.eligibilityService.isGroupLevelAccess$.subscribe((r) => {
      this.isGroupLevelAccess = r;
      if (this.isGroupLevelAccess) {
        this.sharedService.getUserGroupsAccess({ emailId: this.authService.userEmail, clientId: this.searchMemberFormGroup.value.client }).pipe(tap((res) => {
          this.groupLevelAccess = res;
        })).subscribe();
      }
    });

    this.searchMemberFormGroup.valueChanges.subscribe((formValue) => {
      const formControls = Object.keys(formValue);
      const areAllFieldsEmpty = formControls.every((controlName) => {
        const control = this.searchMemberFormGroup.get(controlName);
        return (
          control.value === null ||
          control.value === undefined ||
          control.value === ''
        );
      });

      if (areAllFieldsEmpty) {
        this.allFieldsEmpty = true;
        // console.log('All fields are empty');
      } else {
        this.allFieldsEmpty = false;
        // console.log('All fields are not empty');
      }
    });

    this.listOfClients$ = this.benefitService.getClientsListByAccess().pipe(
      tap((res) => {
        this.clientList = res;
        const hasAccessToAddMember = res.filter((e) => e.olA_Access_Type !== UserAccessLevel.LEVEL_A && e.olA_Access_Type !== UserAccessLevel.LEVEL_E);
        this.showAddMember = hasAccessToAddMember.length !== 0;
      })
    );

    this.loader.hideLoader();

    let accessLevel;
    this.subscription.push(this.sharedService.accessLevel$.subscribe((v) => {
      accessLevel = v
      if (accessLevel) {
        this.memberMenus = this.utilService.getEligibilityMenus(accessLevel);

        if (this.memberMenus.find((x) => x.key === EligibilityMenus.AddNewMember)) {
          const addMemberMenu = this.memberMenus.find(
            (x) => x.key === EligibilityMenus.AddNewMember
          );
          addMemberMenu.show = false;
          this.hasAccessToAddMember = true;
        }
      }
    }));

    // Update member details based on search criteria
    this.members$ = this.getMembers();

    // console.log('eligibilityMenus', this.memberMenus);

    this.subscription.push(this.eligibilityService.selectedClient3$
      .pipe(
        filter((client) => !!client),
        distinctUntilChanged()
      )
      .subscribe((client) => {
        // console.log('Client in subject member search:', client);

        this.searchMemberFormGroup.get('client').setValue(client.clientId);
        if (this.eligibilityService.searchCriteriaCache?.client !== this.searchMemberFormGroup.value.client) {
          this.onTabChange();
          this.onClientSelection(client);
        }
      }));
  }

  onChangeIdType(event: any) {
    this.searchMemberFormGroup.controls['idValue'].patchValue('');
    this.dataLoaded = false;
  }

  formatSSNString(ssn: string): string {
    return formatSSN(ssn);
  }

  onTabChange(event?: any) {
    // console.log('On tab change:', event);
    this.searchMemberFormGroup.controls['idType'].patchValue('');
    this.searchMemberFormGroup.controls['idValue'].patchValue('');
    this.searchMemberFormGroup.controls['firstName'].patchValue('');
    this.searchMemberFormGroup.controls['lastName'].patchValue('');
    this.dataLoaded = false;
  }

  get isLevelD(): boolean {
    return this.sharedService.isLevelD();
  }

  public getToolTip(): string {
    return 'Update Member';
  }

  public checkIfCoverageExpired(element) {
    return new Date(element.coverage.endDate).valueOf() < new Date().valueOf();
  }

  public checkIfCoverageIsStartingSoon(element) {
    return (
      new Date(element.coverage.startDate).valueOf() > new Date().valueOf()
    );
  }

  public isValidForm(): boolean {
    let isValid: boolean = false;
    if (this.eligibilityService.selectedIndex === 0) {
      isValid =
        this.searchMemberFormGroup.controls.client.valid &&
        this.searchMemberFormGroup.controls.idType.valid &&
        this.searchMemberFormGroup.controls.idValue.valid;
    } else {
      isValid =
        this.searchMemberFormGroup.controls.client.valid &&
        this.searchMemberFormGroup.controls.lastName.valid &&
        this.searchMemberFormGroup.controls.firstName.valid &&
        (this.searchMemberFormGroup.controls.firstName.value ||
          this.searchMemberFormGroup.controls.lastName.value);
    }
    return isValid;
  }

  private getMembers(): Observable<MemberDetails[]> {
    return defer(() => {
      return this.eligibilityService.searchMembers().pipe(
        map((res) => {
          if (res) {
            if (this.isGroupLevelAccess) {
              res.forEach(m => {
                const matchingGroup = this.groupLevelAccess.find(r => r.groupId === m.coverage.groupNumber);
                if (matchingGroup) {
                  m.accessLevel = matchingGroup.benefitEligibilityAccessType;
                }
              });
            }
            this.dataSource = new MatTableDataSource(res);
            setTimeout(() => {
              this.dataSource.paginator = this.paginator;
            }, 0);
            this.dataLoaded = true;
            // console.log(
            //   'Subscription:',
            //   !!this.eligibilityService.selectedClientSubject['_value']
            // );
          }
          return res;
        }),
        catchError((err) => {
          this.dataLoaded = false;
          return of([]);
        })
      );
    });
  }

  public searchMembers() {
    let searchQuery: MemberSearchRequest = {
      clientId: this.searchMemberFormGroup.controls.client.value,
    };
    // console.log('Search query:', searchQuery);

    if (this.eligibilityService.selectedIndex === 0) {
      let idType = this.searchMemberFormGroup.controls.idType.value;
      let idValue =
        this.searchMemberFormGroup.controls.idType.value === 'SSN'
          ? clearSsnFormat(this.searchMemberFormGroup.controls.idValue.value)
          : this.searchMemberFormGroup.controls.idValue.value.trim();

      switch (idType) {
        case 'Card/Alt ID':
          searchQuery.cardId = idValue;
          break;
        case 'SSN':
          searchQuery.SSN = idValue;
          break;
        // case 'EmpiRx Member ID':
        //   searchQuery.empirxMemberId = idValue;
        //   break;
        case 'Client Internal Member ID':
          searchQuery.clientInternalMemberId = idValue;
          break;
      }
    } else {
      searchQuery.firstName =
        this.searchMemberFormGroup.controls.firstName.value.trim();
      searchQuery.lastName = this.searchMemberFormGroup.controls.lastName.value.trim();
    }
    // console.log('Search query:', searchQuery);
    // this.eligibilityService.clientChosenMemberSearch.next(searchQuery.clientId);
    if (
      searchQuery.clientId === '19121' &&
      this.searchMemberFormGroup.controls.idType.value === 'Card/Alt ID'
    ) {
      searchQuery.cardId = trimIfUNE(searchQuery.cardId);
    }
    this.appInsightService.trackPageView(AppCenterAnalytics.GET_ONLINE_ELIGIBILITY_SEARCH_REQUEST, { data: searchQuery });
    this.eligibilityService.queryString.next(
      this.eligibilityService.getSearchQuery(searchQuery)
    );
  }

  public resetForm() {
    // console.log(this.searchMemberFormGroup.value);
    this.searchMemberFormGroup.controls['idType'].reset();
    this.searchMemberFormGroup.controls['idValue'].reset();
    this.searchMemberFormGroup.controls['firstName'].reset();
    this.searchMemberFormGroup.controls['lastName'].reset();
  }

  public addNewMember(): void {
    this.router.navigateByUrl('/management/online-eligibility/add-new-member');
  }
  public addNewDependent(member: MemberDetails): void {
    const queryParams = {
      clientId: member.clientId,
      cardId: member.cardId,
      patientId: member.patientId,
      firstName: member.firstName,
      groupNumber: member.coverage.groupNumber
    };
    this.router.navigate(['/management/online-eligibility/add-new-dependent'], {
      queryParams: queryParams,
    });
  }
  public updateMember(member: MemberDetails): void {
    // console.log('Member details:', member);
    const queryParams = {
      clientId: member.clientId,
      cardId: member.cardId,
      patientId: member.patientId,
      firstName: member.firstName,
      groupNumber: member.coverage.groupNumber
    };
    this.router.navigate(['/management/online-eligibility/update-member'], {
      queryParams: queryParams,
    });
  }
  public updateCoverage(): void {
    this.router.navigateByUrl('/management/online-eligibility/update-coverage');
  }

  public goToMenu(menu: EligibilityMenu, member: MemberDetails) {
    const queryParams = {
      clientId: member.clientId,
      cardId: member.cardId,
      patientId: member.patientId,
      firstName: member.firstName,
      groupNumber: member.coverage.groupNumber
    };
    this.router.navigate([menu.route], { queryParams: queryParams });
  }

  public onClientSelection(client: any) {
    // console.log('on Client selection', client.value);
    // this.eligibilityService.selectedClientSubject.next(client);
    this.searchMemberFormGroup.controls['idType'].patchValue('');
    this.searchMemberFormGroup.controls['idValue'].patchValue('');
    this.searchMemberFormGroup.controls['firstName'].patchValue('');
    this.searchMemberFormGroup.controls['lastName'].patchValue('');
    this.dataLoaded = false;
    this.isDemoClient(client.clientId);
  }

  onManuallyClientSelect(client: Clients) {
    this.eligibilityService.setSelectedClient2(client);
    this.eligibilityService.setSelectedClient3(client);
    this.sharedService.updateAccessLevel(client.olA_Access_Type);
    this.eligibilityService.updateIsMigratedValue(client.migrationFlag && client.migrationFlag === 'Y');
    this.eligibilityService.updateIsGroupLevelAccessValue(client?.groupLevelFlag);
  }

  isDemoClient(clientId: string) {
    this.loader.showLoader();
    this.demoClientService.checkDemoClient(clientId).subscribe({
      next: (res) => {
        // console.log('Response on isDemoClient:', res);
        this.demoClientService.updateDemoClientFlag = res;
      },
      error: (err) => {
        this.loader.hideLoader();
      },
      complete: () => {
        this.loader.hideLoader();
      },
    });
  }

  formatPhoneNumber(phone: string): string {
    return formatPhone(phone);
  }

  public toggle() {
    this.allRowsExpanded = !this.allRowsExpanded;
    this.expandedElement = null;
  }

  public getCoverageTableDatasource(
    data: Coverage[]
  ): MatTableDataSource<Coverage> {
    // console.log('Data found:', data);
    return new MatTableDataSource(data);
  }

  public getMenuOptions(member: MemberDetails): EligibilityMenu[] {
    let accessLevel;
    let memberMenus;
    this.subscription.push(this.sharedService.accessLevel$.subscribe((v) => {
      accessLevel = v
      if (accessLevel) {
        memberMenus = this.utilService.getEligibilityMenus(member.accessLevel || accessLevel);
      }
    }));
    const array = Object.assign([], memberMenus);
    this.checkIfHasMenuAccess(array, EligibilityMenus.AddNewMember);
    this.checkIfHasMenuAccess(array, EligibilityMenus.AddNewDependent);
    this.checkIfHasMenuAccess(array, EligibilityMenus.UpdateMember);

    if (member.relationshipCode !== this.cardHolderRC) {
      this.checkIfHasMenuAccess(array, EligibilityMenus.RequestIdCard);
      const actionsToBeRemoved = [
        EligibilityMenus.DrugPricing,
        EligibilityMenus.PharmacySearch,
        EligibilityMenus.PrintDigitalIdCard,
      ];
      this.checkIfCoverageHasExpired(member, array, actionsToBeRemoved);
    }
    if (member.relationshipCode === this.cardHolderRC) {
      const actionsToBeRemoved = [
        EligibilityMenus.DrugPricing,
        EligibilityMenus.PharmacySearch,
        EligibilityMenus.PrintDigitalIdCard,
        EligibilityMenus.RequestIdCard,
      ];
      this.checkIfCoverageHasExpired(member, array, actionsToBeRemoved);
    }
    if (this.clientIdsWithoutDigitalIdCard.includes(member.clientId)) {
      const actionsToBeRemoved = [
        EligibilityMenus.PrintDigitalIdCard,
      ];
      this.removeMenus(actionsToBeRemoved, array);
    }



    return array;
  }

  private checkIfCoverageHasExpired(
    member: MemberDetails,
    array: any[] & EligibilityMenu[],
    actionsToBeRemoved: string[]
  ) {
    if (new Date(member.coverage.endDate).valueOf() < new Date().valueOf()) {
      this.removeMenus(actionsToBeRemoved, array);
    }
  }

  private removeMenus(actionsToBeRemoved: string[], array: EligibilityMenu[]) {
    actionsToBeRemoved.forEach((i) => {
      const index = array.findIndex((x) => x.key === i);
      if (index !== -1) {
        array.splice(index, 1);
      }
    });
  }

  private checkIfHasMenuAccess(array: any[] & EligibilityMenu[], menu: string) {
    const index = array.findIndex((x) => x.key === menu);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  // public showAddNewMemberButton(): boolean {
  //   return !!this.memberMenus.find(
  //     (x) => x.key === EligibilityMenus.AddNewDependent
  //   );
  // }

  public onSelectionChange(event) {
    const selectedClient = this.clientList.filter((c) => c.clientId === event.value)[0];
    this.onClientSelection(selectedClient);
    this.onManuallyClientSelect(selectedClient);
  }

  public checkMenuAccess(
    member: MemberDetails,
    menuName: 'UpdateMember' | 'AddNewDependent'
  ) {
    let accessLevel;
    let memberMenus;
    this.subscription.push(this.sharedService.accessLevel$.subscribe((v) => {
      accessLevel = v
      if (accessLevel) {
        memberMenus = this.utilService.getEligibilityMenus(member.accessLevel || accessLevel);
      }
    }));
    const array = Object.assign([], memberMenus);
    const menu = array.find((x) => x.key === menuName);

    if (menuName === 'AddNewDependent') {
      return !!menu && member.relationshipCode === this.cardHolderRC;
    } else {
      return !!menu;
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.forEach((s) => s.unsubscribe());
    this.eligibilityService.searchCriteriaCache =
      this.searchMemberFormGroup.value;
  }
}
