<!-- Form Section -->
<ng-container *ngIf="listOfClients$ | async as clients">
  <div class="form-wrapper">
    <form [formGroup]="benefitsFormGroup" class="benefits-form">
      <div class="mat-row pad-b-1">
        <div class="mat-12">
          <mat-label>Client<span class="asterisk-mark">*</span></mat-label>
          <mat-form-field floatLabel="never" class="full-width">
            <mat-select placeholder="Select Client" (selectionChange)="onSelectionChange($event)"
              formControlName="clientId" matNativeControl>
              <ng-container *ngFor="let client of clients">
                <mat-option [value]="client.clientId">
                  {{client.clientId}}-{{ client.clientName }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="mat-row pad-b-1">
        <div class="mat-6 pad-r-1" [ngClass]="
          this.benefitsFormGroup.controls.planId.disabled
            ? 'disabled-control'
            : null
        ">
          <mat-label>Plan Name<span class="asterisk-mark">*</span></mat-label>
          <mat-form-field floatLabel="never" class="full-width">
            <mat-select placeholder="Select Plan Name" (selectionChange)="onPlanSelection($event)"
              formControlName="planId">
              <ng-container *ngIf="listOfPlans$ | async as plans">
                <mat-option *ngFor="let plan of plans" [value]="plan.planId">
                  {{ plan.planName }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="mat-6" [ngClass]="
            this.benefitsFormGroup.controls.groupId.disabled
              ? 'disabled-control'
              : null
          ">
          <mat-label>Group<span class="asterisk-mark">*</span></mat-label>
          <mat-form-field floatLabel="never" class="full-width">
            <mat-select placeholder="Select Group" (selectionChange)="onGroupSelection($event)"
              formControlName="groupId">
              <ng-container *ngIf="listOfGroups$ | async as groups">
                <mat-option *ngFor="let group of groups" [value]="group.groupId">
                  {{ group.groupName }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="mat-row pad-b-1">
        <div class="mat-6 pad-r-1 plan-type-select" *ngIf="benefitsFormGroup.controls.groupId.value">
          <mat-label>Plan Type</mat-label>
          <mat-form-field floatLabel="never" class="full-width">
            <mat-select placeholder="Not Available" formControlName="planType">
              <ng-container>
                <mat-option *ngFor="let plan of planTypes" [value]="plan">
                  {{ plan }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </form>
    <mat-divider></mat-divider>
  </div>
</ng-container>