<div class="page-wrapper">
  <a class="back-arrow-inner" style="cursor: pointer" (click)="navigateBackToMemberSearch()"><mat-icon
      matListIcon>arrow_back</mat-icon> Member Search</a>
  <!-- Page Header -->
  <div class="page-header">
    <h2>Claim Search</h2>
  </div>
  <ng-container *ngIf="!loadingClaims">
    <ng-container *ngIf="selectedMemberDetails$ | async as selectedMemberDetails">
      <!-- Member Detail card -->
      <div class="detail-card">
        <div class="client-id-name">
          <h4>Member</h4>
          <span>(Client: {{selectedMemberDetails.clientId}}-{{selectedMemberDetails.client_full_name}})</span>
        </div>
        <div class="mat-row">
          <div class="mat-2">
            <label class="card-label">Name</label>
            <p>
              {{ selectedMemberDetails.firstName }}
              {{ selectedMemberDetails.lastName }}
            </p>
          </div>
          <div class="mat-2">
            <label class="card-label">Person Code</label>
            <p>{{ selectedMemberDetails.personCode }}</p>
          </div>
          <div class="mat-2">
            <label class="card-label">DOB</label>
            <p>{{ selectedMemberDetails.DOB | timeStampToDate }}</p>
          </div>
          <div class="mat-2">
            <label class="card-label">Relationship</label>
            <p>{{selectedMemberDetails.relationshipCode}} - {{relationShip[selectedMemberDetails.relationshipCode] }}
            </p>
          </div>
          <div class="mat-2">
            <label class="card-label">Card/Alt ID</label>
            <p>{{ selectedMemberDetails.cardId }}</p>
          </div>
          <div class="mat-2">
            <label class="card-label">Coverage Type</label>
            <p>
              {{
              displayCoverageType[selectedMemberDetails?.coverage?.coverageType]
              }}
            </p>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>

      <h3 class="search-line">
        <span>Search Criteria</span>
        <span>
          Patient ID
          <span class="number-id">{{ memberDetails?.patientId }}</span></span>
      </h3>

      <div class="form-wrapper claim-advance-search-filter">
        <form [formGroup]="claimSearchFormGroup">
          <div class="mat-row pad-b-1">
            <div class="pad-r-1">
              <mat-label>From<span class="asterisk-mark">*</span></mat-label>
              <mat-form-field floatLabel="never" class="claim-datepicker-search mat-datepicker-alignment">
                <input matInput
                  [max]="claimSearchFormGroup.value.toDateControl? claimSearchFormGroup.value.toDateControl: maxDate"
                  [matDatepicker]="formDatePicker" formControlName="formDateControl" matNativeControl
                  placeholder="Enter Date" />
                <mat-datepicker-toggle matIconSuffix [for]="formDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #formDatePicker></mat-datepicker>
              </mat-form-field>
              <app-custom-mat-hint [formGroup]="claimSearchFormGroup" [isCheckTouch]="checkTouchForForm"
                controlName="formDateControl"></app-custom-mat-hint>
            </div>
            <div class="pad-r-1" [ngClass]="{'disabled-date':!claimSearchFormGroup.value.formDateControl}">
              <mat-label>To<span class="asterisk-mark">*</span></mat-label>
              <mat-form-field floatLabel="never" class="claim-datepicker-search mat-datepicker-alignment">
                <input matInput [disabled]="!claimSearchFormGroup.value.formDateControl"
                  [min]="claimSearchFormGroup.value.formDateControl" [max]="maxDate" matNativeControl
                  [matDatepicker]="toDatePicker" formControlName="toDateControl" placeholder="Enter Date" />
                <mat-datepicker-toggle matIconSuffix [for]="toDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #toDatePicker></mat-datepicker>
              </mat-form-field>
              <app-custom-mat-hint [formGroup]="claimSearchFormGroup" [isCheckTouch]="checkTouchForForm"
                controlName="toDateControl"></app-custom-mat-hint>
            </div>
            <!--Claim status should be visible only for Level D and Level E users-->
            <div *ngIf="isShowClaimStatusSelection()" class="pad-r-1">
              <mat-label>Claim Status<span class="asterisk-mark">*</span></mat-label>
              <mat-form-field floatLabel="never" class="claim-datepicker-search">
                <mat-select placeholder="Select Claim Status" formControlName="claimStatus" matNativeControl>
                  <mat-option *ngFor="let claimStatus of claimTypesArray" [value]="claimStatus">
                    {{ claimStatus }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <app-custom-mat-hint controlName="claimStatus"></app-custom-mat-hint>
            </div>
          </div>
          <div *ngIf="dateDifferenceValidator()">
            <p style="color: #FF8605">
              The date range cannot be more than 24 months.
            </p>
          </div>
          <div class="mat-row pad-b-1">
            <div class="pad-r-1">
              <mat-label class="flex">Rx Number
                <div class="popover claim-tooltip">
                  <mat-icon class="info-icon">info</mat-icon>
                  <div class="popover-container">
                  Please enter exact Rx Number.
                  </div>
                </div>
              </mat-label>
              <!-- <mat-form-field floatLabel="never" class="full-width mat-datepicker-alignment"> -->
                <div class="search-input-block">
                  <img src="../../../../assets/images/search-icon.svg" alt="" />
                  <input type="text" formControlName="rxNumber" class="form-control" placeholder="Search" #input />
                </div>
              <!-- </mat-form-field> -->
            </div>
            <div class="pad-r-1">
              <mat-label class="flex">Drug Name
                <div class="popover claim-tooltip">
                  <mat-icon class="info-icon">info</mat-icon>
                  <div class="popover-container">
                    Please enter minimum 3 characters for refined search.
                  </div>
                </div>
              </mat-label>
              <!-- <mat-form-field floatLabel="never" class="full-width mat-datepicker-alignment"> -->
                <div class="search-input-block">
                  <img src="../../../../assets/images/search-icon.svg" alt="" />
                  <input type="text" formControlName="drugName" class="form-control" placeholder="Search" #input />
                </div>
              <!-- </mat-form-field> -->
            </div>
            <div class="pad-r-1">
              <mat-label class="flex">Pharmacy
                <div class="popover claim-tooltip">
                  <mat-icon class="info-icon">info</mat-icon>
                  <div class="popover-container">
                    Please enter minimum 3 characters for refined search.
                  </div>
                </div>
              </mat-label>
              <!-- <mat-form-field floatLabel="never" class="full-width mat-datepicker-alignment"> -->
                <div class="search-input-block">
                  <img src="../../../../assets/images/search-icon.svg" alt="" />
                  <input type="text" formControlName="pharmacy" class="form-control" placeholder="Search" #input />
                </div>
              <!-- </mat-form-field> -->
            </div>
            <div class="mat-3">
              <button class="primary-btn claim-search-btn"
                [disabled]="dateDifferenceValidator() || claimSearchFormGroup.invalid" (click)="resetSearch()"
                mat-button>
                Search
              </button>
            </div>
          </div>
        </form>
      </div>
      <mat-divider></mat-divider>
    </ng-container>

    <div *ngIf="claimSearchList$ | async as claims">
      <h3 class="form-heading mar-b-0">
        {{totalLength}} Items Found
      </h3>
      <p *ngIf="
        userAccessLevel === userAccessLevels.LEVEL_D || userAccessLevel === userAccessLevels.LEVEL_E || userAccessLevel === userAccessLevels.LEVEL_C
      ">
        To view a comparison of claims, check any two claims from the list and
        click Compare Claims.
      </p>
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="clickable-row mat-elevation-z8 mar-b-1">
        <ng-container matColumnDef="select" *ngIf="
        userAccessLevel === userAccessLevels.LEVEL_D || userAccessLevel === userAccessLevels.LEVEL_E || userAccessLevel === userAccessLevels.LEVEL_C
      ">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox [disabled]="selectedClaims.length === 2 && !element.selected"
              (change)="selectRow(element)"></mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="fillDate">
          <th mat-header-cell *matHeaderCellDef>Rx Fill Date</th>
          <td (click)="navigateClaimSearchDetail(element)" mat-cell *matCellDef="let element">
            {{ element.fillDate | timeStampToDate }}
          </td>
        </ng-container>

        <ng-container matColumnDef="rxNumber">
          <th mat-header-cell *matHeaderCellDef>Rx Number</th>
          <td (click)="navigateClaimSearchDetail(element)" mat-cell *matCellDef="let element">
            {{ element.rxNumber }}
          </td>
        </ng-container>

        <ng-container matColumnDef="authNum">
          <th mat-header-cell *matHeaderCellDef>Auth Number</th>
          <td (click)="navigateClaimSearchDetail(element)" mat-cell *matCellDef="let element">
            {{ element.authNum? element.authNum: '-' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="drugName">
          <th mat-header-cell *matHeaderCellDef>Drug Name</th>
          <td (click)="navigateClaimSearchDetail(element)" mat-cell *matCellDef="let element">
            {{ element.drugName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="pharmacyName">
          <th mat-header-cell *matHeaderCellDef>Pharmacy</th>
          <td (click)="navigateClaimSearchDetail(element)" mat-cell *matCellDef="let element">
            {{ element.pharmacyName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="copay">
          <th mat-header-cell *matHeaderCellDef>Co-pay</th>
          <td (click)="navigateClaimSearchDetail(element)" mat-cell *matCellDef="let element">
            {{ element.copay? (element.copay | currency : "USD") : '-' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="patientPay">
          <th mat-header-cell *matHeaderCellDef>Patient Pay</th>
          <td (click)="navigateClaimSearchDetail(element)" mat-cell *matCellDef="let element">
            {{ element.patientPay? (element.patientPay | currency : "USD") : '-'}}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td (click)="navigateClaimSearchDetail(element)" mat-cell *matCellDef="let element">
            {{ element.status }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns"
          [class.example-expanded-row]="expandedElement === element"></tr>
        <tr tabindex="0" class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="9" role="status">No Data Found - Please check Search criteria</td>
        </tr>
      </table>

    </div>

    <div [hidden]="hidePaginator" class="claim-footer">
      <div class="accordion-table-footer mb-3" fxLayout="row">
        <ng-container *ngIf="eligibilityService.isMigratedClient$ | async as isMigrated">
          <div flex fxLayout="row" *ngIf="isMigrated && doseHavePastData">
            <p class="claim-text">Don't find claims?</p>
            <button mat-button class="primary-btn" (click)="requestClaimsData()">
              Request
            </button>
          </div>
        </ng-container>
        <mat-paginator #paginator (page)="onPageChange($event)" [pageIndex]="this.currentPageIndex"
          [pageSizeOptions]="[10, 20, 30]" [length]="this.totalLength" showFirstLastButtons></mat-paginator>
      </div>


      <mat-divider></mat-divider>

      <div fxLayout="row" class="button-row">
        <div class="mat-12" fxLayoutAlign="end end">
          <button class="primary-btn" *ngIf="
          userAccessLevel === userAccessLevels.LEVEL_D || userAccessLevel === userAccessLevels.LEVEL_E || userAccessLevel === userAccessLevels.LEVEL_C
        " [disabled]="selectedClaims.length < 2" (click)="navigateClaimSearchCompare()" mat-button>
            Compare Claims
          </button>
          <button mat-button (click)="navigateBackToMemberSearch()" class="secondary-btn">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>