import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import { MemberDetails } from '../../../interfaces/member.interface';
import { map, switchMap, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { EligibilityService } from '../../../core/services/eligibility/eligibility.service';
import { BenefitManagementService } from '../../../core/services/benefit-management/benefit-management.service';
import { AppInsightsService } from '../../../services/app-insights/app-insights.service';
import { AppCenterAnalytics } from '../../../services/app-insights/app-insights';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppConstants } from '../../../app.constants';
import { LoaderService } from '../../services/loader/loader.service';
import { IClinicalReviewDocRequest } from '../../../interfaces/clinical-review-doc-request.interface';
import { SharedService } from '../../../services/shared/shared.service';
import { ClinicalReviewStatus } from '../../../interfaces/clinical-review-status.interface';
import { ClinicalReviewStatusResponse } from '../../../interfaces/clinical-reviews.interface';

@Component({
  selector: 'app-clinical-reviews',
  templateUrl: './clinical-reviews.component.html',
  styleUrls: ['./clinical-reviews.component.scss']
})
export class ClinicalReviewsComponent implements OnInit, OnDestroy {
  public subscription: Subscription = new Subscription()
  public selectedMemberDetails$: Observable<Partial<MemberDetails>>;
  public memberDetails: MemberDetails;
  public migratedFlag: string;
  public accessLevel: string;
  public displayCoverageType = AppConstants.DISPLAY_COVERAGE_TYPE;
  public relationShip = AppConstants.RELATIONSHIP_VALUES;
  public totalLength!: number;
  public displayedColumns: string[] = [
    'inquiryId',
    'drugName',
    'memberName',
    'createDate',
    'requestedBy',
    'status',
    'statusDescription',
    'documentUrl'
  ];
  public dataSource: MatTableDataSource<ClinicalReviewStatus> = new MatTableDataSource([] as ClinicalReviewStatus[]);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  public clinicalReviewsResponse!: ClinicalReviewStatusResponse;
  public showTable: boolean = false;
  constructor(
    private readonly router: Router,
    public eligibilityService: EligibilityService,
    private activatedRoute: ActivatedRoute,
    private benefitService: BenefitManagementService,
    private readonly appInsightService: AppInsightsService,
    private loaderService: LoaderService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.appInsightService.trackPageView(AppCenterAnalytics.CLINICAL_REVIEWS);
    this.subscription.add(this.sharedService.accessLevel$.subscribe((v) => {
      this.accessLevel = v;
    }));
    this.selectedMemberDetails$ = this.activatedRoute.queryParams.pipe(
      switchMap((res) => {
        const reqObj = { loading: true, ...res }
        return this.eligibilityService.getMemberDetailsById(reqObj).pipe(
          map((res) => {
            this.memberDetails = res[0];
            return res[0];
          }),
          tap(() => this.checkGroupLevelAccess()),
          tap(() => this.getClinicalReviews()),
        );
      })
    );
  }

  getClinicalReviews(): void {
    let reqObj = {
      cardId: this.memberDetails.cardId,
      personCode: this.memberDetails.personCode,
      clientId: this.memberDetails.clientId,
      migrationFlag: this.migratedFlag,
      accessLevel: this.accessLevel,
    }
    this.loaderService.showLoader();
    this.subscription.add(this.eligibilityService.getClinicalReviews(reqObj).subscribe((clinicalReviews) => {
      this.showTable = true;
      this.loaderService.hideLoader();
      if (clinicalReviews) {
        this.appInsightService.trackPageView(AppCenterAnalytics.CLINICAL_REVIEWS_API_SUCCESS);
        this.clinicalReviewsResponse = clinicalReviews;
        let displayData = this.clinicalReviewsResponse.clinicalReviews
          .sort((a, b) => new Date(b.createDate).valueOf() - (new Date(a.createDate).valueOf()));
        this.dataSource = new MatTableDataSource(displayData);
        this.totalLength = this.dataSource.data.length;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        if (this.dataSource.paginator) {
          this.dataSource.paginator.firstPage();
        }
      }
    },
      (err) => {
        this.appInsightService.trackPageView(AppCenterAnalytics.CLINICAL_REVIEWS_API_SUCCESS, { Response: err });
        this.loaderService.hideLoader();
      }));
  }

  private checkGroupLevelAccess() {
    this.subscription.add(this.benefitService.getClientsListByAccess().pipe(
      tap((clients) => {
        const selectedClient = clients.find(c => c.clientId === this.memberDetails.clientId);
        this.migratedFlag = selectedClient?.migrationFlag ? selectedClient?.migrationFlag : AppConstants.UTILS.N;
        this.eligibilityService.updateIsMigratedValue(selectedClient.migrationFlag && selectedClient.migrationFlag === AppConstants.UTILS.Y)
        this.eligibilityService.updateIsGroupLevelAccessValue(selectedClient?.groupLevelFlag);
      })
    ).subscribe());
  }

  public processFile(docUrl: string, inquiryId: number, action: string): void {
    const url = new URL(docUrl);
    if (url.search) {
      const queryParams = {};
      url.searchParams.forEach((value, key) => {
        queryParams[key] = value;
      });
      this.loaderService.showLoader();
      const reqBody: IClinicalReviewDocRequest = {
        documentName: queryParams['documentName'],
        documentUrl: queryParams['documentUrl'],
        documentType: queryParams['documentType'],
        migrationFlag: this.migratedFlag,
        accessLevel: this.accessLevel,
      }
      this.subscription.add(this.eligibilityService.getClinicalReviewDocument(reqBody).subscribe((res: Blob) => {
        this.appInsightService.trackPageView(AppCenterAnalytics.CLINICAL_REVIEWS_DOC_API_SUCCESS);
        const blob = new Blob([res], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);
        if (action === AppConstants.UTILS.VIEW) {
          this.appInsightService.trackPageView(AppCenterAnalytics.CLINICAL_REVIEWS_PDF_VIEWED);
          const newWindow = window.open(fileURL, '_blank');
          // Handle pop-up blockers gracefully
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            alert('Please allow pop-ups for this site to view the PDF.');
          }
          this.loaderService.hideLoader();
        } else if (action === AppConstants.UTILS.DOWNLOAD) {
          this.appInsightService.trackPageView(AppCenterAnalytics.CLINICAL_REVIEWS_PDF_DOWNLOAD);
          var anchor = document.createElement("a");
          anchor.download = 'Clinical Review ' + inquiryId;
          anchor.href = fileURL;
          anchor.click();
          this.loaderService.hideLoader();
        }
      }, (error: string) => {
        this.appInsightService.trackPageView(AppCenterAnalytics.CLINICAL_REVIEWS_DOC_API_ERROR, { Response: error });
        this.loaderService.hideLoader();
      }));
    } else {
      this.loaderService.hideLoader();
    }

  }

  public search(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.totalLength = this.dataSource.filteredData.length;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public navigateBackToMemberSearch(): void {
    this.router.navigateByUrl('/management/online-eligibility');
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
