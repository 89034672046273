import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { ErrorComponent } from 'src/app/core/components/error/error.component';
import { EligibilityService } from 'src/app/core/services/eligibility/eligibility.service';
import {
  ClaimRequestInterface,
  ClaimSearchDetailsResponse,
} from 'src/app/interfaces/claim-search-detail-response.interface';
import { LoaderService } from 'src/app/management/services/loader/loader.service';
import { AppCenterAnalytics } from 'src/app/services/app-insights/app-insights';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';
import { SharedService } from '../../../../services/shared/shared.service';
import { UserAccessLevel } from '../../../../interfaces/user-access.interface';
import { BenefitManagementService } from '../../../../core/services/benefit-management/benefit-management.service';

@Component({
  selector: 'app-claim-search-detail',
  templateUrl: './claim-search-detail.component.html',
  styleUrls: ['./claim-search-detail.component.scss'],
})
export class ClaimSearchDetailComponent implements OnInit, OnDestroy {
  public claimSearchDetails$!: Observable<ClaimSearchDetailsResponse>;
  public claimDetails: Partial<ClaimSearchDetailsResponse> = {};
  thirdPartySub: Subscription = new Subscription();
  thirdPartyAssistance: string = '';
  public userAccessLevel: string;
  public migrationFlag: string;
  public userAccessLevels = UserAccessLevel;
  constructor(
    public router: Router,
    private eligibilityService: EligibilityService,
    private readonly loader: LoaderService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private appInsightsService: AppInsightsService,
    private matDialog: MatDialog,
    private sharedService: SharedService,
    private benefitService: BenefitManagementService
  ) { }

  ngOnInit(): void {
    this.sharedService.accessLevel$.subscribe((v) => {
      this.userAccessLevel = v;
    });
    this.eligibilityService.isMigratedClient$.subscribe((f) => this.migrationFlag = f ? 'Y' : 'N');
    this.appInsightsService.trackPageView(
      AppCenterAnalytics.CLAIM_SEARCH_DETAIL
    );
    this.loader.showLoader();
    let errMessage = '';
    let queryParams;
    this.claimSearchDetails$ = this.activatedRoute.queryParams.pipe(
      switchMap((res) => {
        queryParams = res;
        return this.checkMigrationFlag(res);
      }),
      switchMap((res) => {
        return this.eligibilityService
          .getClaimSearchDetails({
            claimId: queryParams.claimId,
            patientId: queryParams.patientId,
            clientId: queryParams.clientId,
            authNum: queryParams.authNum,
            migrationFlag: this.migrationFlag,
            accessLevel: this.userAccessLevel
          })
          .pipe(
            tap((res) => {
              this.claimDetails = res;
              // console.log('Claim details:', res);
              this.loader.hideLoader();
            }),
            catchError((err) => {
              // console.log('Err in search claims:', err);
              errMessage = err.error.errorMessage;
              const dialogRef: MatDialogRef<ErrorComponent> =
                this.matDialog.open(ErrorComponent, {
                  width: '300px',
                  data: {
                    title: 'ERROR',
                    message: err.error.errorMessage,
                  },
                });
              dialogRef.afterClosed().subscribe(() => {
                // this.router.navigateByUrl('/management/online-eligibility');
                this.location.back();
              });
              return of({
                clientId: '-',
                clientName: '-',
                groupId: null,
                groupName: '-',
                cardId: '-',
                name: '-',
                gender: '',
                dob: '',
                personCode: '-',
                relationshipCode: '-',
                claimId: '-',
                processDate: '',
                rxNumber: '-',
                rxFillDate: '',
                fillNumber: '',
                writtenDate: '',
                quantityPrescribed: null,
                quantityDispensed: null,
                compound: null,
                daw: null,
                daysSupply: null,
                pharmacy: '-',
                pharmacyNPI: '-',
                pharmacyAddress: '-',
                prescription: '-',
                prescriberNPI: '-',
                prescriber: '-',
                medication: '-',
                brandGeneric: '',
                routeOfAdmin: '-',
                multiSourceCode: '-',
                ndc: '-',
                specialty: '-',
                formularyTier: '',
                maintenance: '-',
                priorAuthList: '-',
                steptherapyProtocol: null,
                quantityLimitList: null,
                equivalentMedication: null,
                therapeuticAlternativeMedications: null,
                indication: null,
                primaryDrugCategory: '-',
                additionalDrugCategories: null,
                status: '-',
                processedAsPrimary: '',
                additionalMessage: null,
                rejectCode: '',
                rejectCodeDescription: null,
                planAmount: null,
                totalMemberResponsibility: null,
                thirdPartAssistance: null,
                totalMemberPay: null,
                memberCopay: null,
                memberDeductible: null,
                memberOutOfPocket: null,
                ancillaryCharge: null,
                ingredientCost: null,
                dispensingFee: null,
                shippingCharge: null,
                salesTax: null,
                incentiveAmount: null,
                totalCost: null,
                pricingSourceUsed: '-',
                awp: null,
                pharmacyReimbursement: null,
              } as ClaimSearchDetailsResponse);
            }),
            finalize(() => {
              if (errMessage) {
                const dialogRef: MatDialogRef<ErrorComponent> =
                  this.matDialog.open(ErrorComponent, {
                    width: '300px',
                    data: {
                      title: 'ERROR',
                      message: errMessage,
                    },
                  });
                dialogRef.afterClosed().subscribe(() => {
                  // this.router.navigateByUrl('/management/online-eligibility');
                  this.location.back();
                });
              }
              this.loader.hideLoader();
              this.getThirdPartyAssistance({
                claimId: queryParams.claimId,
                patientId: queryParams.patientId,
                // clientId: 'TEST',
                authNum: queryParams.authNum,
                clientId: queryParams.clientId,
              });
            })
          );
      })
    );
  }

  private checkMigrationFlag(res) {
    return this.benefitService.getClientsListByAccess().pipe(
      tap((clients) => {
        const selectedClient = clients.find(c => c.clientId === res.clientId);
        this.migrationFlag = selectedClient?.migrationFlag ? selectedClient?.migrationFlag : 'N';
        this.eligibilityService.updateIsMigratedValue(selectedClient.migrationFlag && selectedClient.migrationFlag === 'Y')
      })
    )
  }

  getThirdPartyAssistance(reqBody: ClaimRequestInterface) {
    this.loader.showLoader();
    this.thirdPartySub = this.eligibilityService
      .getThirdPartyAssistance(reqBody)
      .subscribe({
        next: (res) => {
          // console.log('Response on third party assistance:', res);
          this.thirdPartyAssistance = res.thirdPartyAssistance;
        },
        error: (err) => {
          // console.log('Error on third party assistance:', err);
          this.loader.hideLoader();
        },
        complete: () => {
          this.loader.hideLoader();
        },
      });
  }

  public navigateBackToClaimSearch(): void {
    this.location.back();
  }

  public getSpecialtyValue(claimDetails): string {
    if (claimDetails && claimDetails.specialty) {
      return claimDetails.specialty.toLowerCase() === 'yes' ? 'Yes' : 'No'
    }
    return 'No';
  }

  ngOnDestroy(): void {
    if (this.thirdPartySub) this.thirdPartySub.unsubscribe();
  }
}
