import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ClaimSearchListResponse } from '../../../interfaces/claim-search-list-response.interface';
import { ClaimSearchResponse } from '../../../interfaces/claim-search-response.interface';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';
import { AppCenterAnalytics } from 'src/app/services/app-insights/app-insights';
import { EligibilityService } from '../../../core/services/eligibility/eligibility.service';
import { MemberDetails } from '../../../interfaces/member.interface';
import { AppConstants, ClaimsStatus } from '../../../app.constants';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { formatDate } from 'src/app/util';
import { BenefitManagementService } from 'src/app/core/services/benefit-management/benefit-management.service';
import { UserAccessLevel } from 'src/app/interfaces/user-access.interface';
import { SharedService } from '../../../services/shared/shared.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RequestClaimsDataDialogComponent } from './request-claims-data-dialog/request-claims-data-dialog.component';
import { ErrorComponent } from '../../../core/components/error/error.component';
import { AuthService } from '../../../core/services/auth/auth.service';
import { LoaderService } from '../../services/loader/loader.service';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-claim-search',
  templateUrl: './claim-search.component.html',
  styleUrls: ['./claim-search.component.scss'],
})
export class ClaimSearchComponent implements OnInit, AfterViewInit, OnDestroy {
  public userAccessLevels = UserAccessLevel;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public maxDate = new Date();
  public displayedColumns: string[] = [
    'fillDate',
    'rxNumber',
    'authNum',
    'drugName',
    'pharmacyName',
    'copay',
    'patientPay',
    'status',
  ];
  dataSource: MatTableDataSource<ClaimSearchResponse> = new MatTableDataSource(
    [] as ClaimSearchResponse[]
  );

  public claimSearchList$!: Observable<ClaimSearchListResponse>;
  public selectedClaims: ClaimSearchResponse[] = [];
  public displayCoverageType = AppConstants.DISPLAY_COVERAGE_TYPE;
  public memberDetails: MemberDetails;
  public relationShip = AppConstants.RELATIONSHIP_VALUES;

  public selectedMemberDetails$: Observable<Partial<any>>;

  public claimSearchFormGroup: FormGroup;
  public userAccessLevel: string;
  public doseHavePastData: boolean;
  public migratedFlag: string;
  public loadingClaims: boolean = false;
  claimTypesArray: string[] = [
    ClaimsStatus.ALL,
    ClaimsStatus.REJECTED,
    ClaimsStatus.PAID_REVERSAL,
    ClaimsStatus.PAID
  ];

  perPage: number = 10;
  prevPerPage: number = 10;
  currentPageIndex: number = 0;
  totalLength: number = 0;
  initiatePaginator: boolean = true;
  hidePaginator: boolean = true;
  public clientEffectiveDate: string;
  total: number = 0;
  index: number = 0;
  previousPage: number;
  indexCount: number;
  nextPage: number;
  pagePointer: string = 'N';
  constructor(
    private readonly router: Router,
    private formBuilder: FormBuilder,
    private readonly appInsightsService: AppInsightsService,
    public eligibilityService: EligibilityService,
    private activatedRoute: ActivatedRoute,
    private benefitService: BenefitManagementService,
    private sharedService: SharedService,
    private matDialog: MatDialog,
    private readonly authService: AuthService,
    private readonly loader: LoaderService,
    private readonly appInsightService: AppInsightsService
  ) {
    this.sharedService.accessLevel$.subscribe((v) => {
      this.userAccessLevel = v;
      if (
        this.userAccessLevel === UserAccessLevel.LEVEL_D ||
        this.userAccessLevel === UserAccessLevel.LEVEL_E ||
        this.userAccessLevel === UserAccessLevel.LEVEL_C
      ) {
        this.displayedColumns.unshift('select');
      }
    })

  }

  ngOnInit(): void {
    this.selectedMemberDetails$ = this.activatedRoute.queryParams.pipe(
      switchMap((res) => {
        return this.eligibilityService.getMemberDetailsById(res).pipe(
          map((res) => {
            this.memberDetails = res[0];
            return res[0];
          }),
          tap(() => this.checkGroupLevelAccess()),
          tap(() => this.loadClaims()),
          tap(() => this.getClientEffectiveDate())
        );
      })
    );
  }

  private getClientEffectiveDate() {
    this.eligibilityService.getClientEffectiveDate(this.memberDetails.clientId).pipe(
      tap((res) => {
        this.clientEffectiveDate = res.clientEffectiveDate;
      }),
    ).subscribe(() => this.doseHavePastData = !this.isDateWithinPastTwoYears(this.clientEffectiveDate));
  }

  private checkGroupLevelAccess() {
    this.benefitService.getClientsListByAccess().pipe(
      tap((clients) => {
        const selectedClient = clients.find(c => c.clientId === this.memberDetails.clientId);
        this.migratedFlag = selectedClient?.migrationFlag ? selectedClient?.migrationFlag : 'N';
        this.eligibilityService.updateIsMigratedValue(selectedClient.migrationFlag && selectedClient.migrationFlag === 'Y')
        this.eligibilityService.updateIsGroupLevelAccessValue(selectedClient?.groupLevelFlag);
      })
    ).subscribe()
  }

  private setSorting(): void {
    setTimeout(() => {
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (
        data: any,
        sortHeaderId: string
      ): any => {
        if (typeof data[sortHeaderId] === 'number' || sortHeaderId === 'rxNumber') {
          return Number(data[sortHeaderId]);
        }
        if (typeof data[sortHeaderId] === 'string') {
          return data[sortHeaderId].toLocaleLowerCase();
        }
      };
    }, 0);
  }

  public isDateWithinPastTwoYears(dateString): boolean {
    const date = new Date(dateString);
    const currentYear = new Date().getFullYear();
    const year = date.getFullYear();
    return year <= currentYear && year >= currentYear - 2;
  }


  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  private loadClaims() {
    const defaultClaimStatus = ClaimsStatus.ALL;

    this.appInsightsService.trackPageView(AppCenterAnalytics.CLAIM_SEARCH);
    const requiredValidator = Validators.required;
    this.claimSearchFormGroup = this.formBuilder.group(
      {
        formDateControl: [
          this.eligibilityService.claimSearchInputs?.fromDate
            ? this.eligibilityService.claimSearchInputs?.fromDate
            : '',
          requiredValidator,
        ],
        toDateControl: [
          this.eligibilityService.claimSearchInputs?.toDate
            ? this.eligibilityService.claimSearchInputs?.toDate
            : '',
          requiredValidator,
        ],
        claimStatus: [this.eligibilityService.claimSearchInputs?.claimStatus
          ? this.eligibilityService.claimSearchInputs?.claimStatus
          : defaultClaimStatus],
        rxNumber: [this.eligibilityService.claimSearchInputs?.rxNumber
          ? this.eligibilityService.claimSearchInputs?.rxNumber
          : ''],
        drugName: [this.eligibilityService.claimSearchInputs?.drugName
          ? this.eligibilityService.claimSearchInputs?.drugName
          : ''],
        pharmacy: [this.eligibilityService.claimSearchInputs?.pharmacyName
          ? this.eligibilityService.claimSearchInputs?.pharmacyName
          : '']
      },
      { validators: this.dateRangeValidator }
    );

    if (this.eligibilityService.claimSearchInputs?.fromDate && this.eligibilityService.claimSearchInputs?.toDate) {
      this.performSearch(this.index, this.pagePointer);
    }

    this.claimSearchList$ = this.getClaimList().pipe(
      tap((res) => {
        if (res && res.pagination) {
          this.dataSource = new MatTableDataSource(res.claims ? res.claims : []);
          this.totalLength = res.pagination.totalRecords;
          this.index = res.index == null ? 0 : res.index;
          this.total = res.count;
          if (this.hidePaginator) {
            this.hidePaginator = false;
          }
          this.setSorting();
        } else {
          this.dataSource = new MatTableDataSource([]);
          this.totalLength = 0;
        }
      }),
      catchError((error) => {
        this.dataSource = new MatTableDataSource([]);
        this.totalLength = 0;
        return of({
          count: 0,
          claims: []
        })
      })
    );
  }

  dateRangeValidator(control: AbstractControl): ValidationErrors | null {
    const fromDate = control.get('formDateControl').value;
    const toDate = control.get('toDateControl').value;

    if (fromDate && toDate && fromDate > toDate) {
      control.get('formDateControl').setErrors({ dateInvalid: true });
      control.get('toDateControl').setErrors({ dateInvalid: true });
      return { dateInvalid: true };
    }
  }

  public navigateBackToMemberSearch(): void {
    this.router.navigateByUrl('/management/online-eligibility');
    this.eligibilityService.clearSearchClaimCache();
    this.eligibilityService.claimSearchCriteria.next(null);
    this.claimSearchFormGroup.reset();
  }

  public navigateClaimSearchDetail(claim: ClaimSearchResponse): void {
    if (
      this.userAccessLevel === UserAccessLevel.LEVEL_D ||
      this.userAccessLevel === UserAccessLevel.LEVEL_E ||
      this.userAccessLevel === UserAccessLevel.LEVEL_C
    ) {
      this.loader.showLoader();
      this.loadingClaims = true;
      this.router.navigateByUrl(
        `/management/online-eligibility/claim-search-detail?claimId=${claim.claimId}&authNum=${claim.authNum}&patientId=${claim.patientId}&clientId=${this.memberDetails.clientId}&groupNumber=${this.memberDetails.coverage.groupNumber}`
      );
    }
  }

  public navigateClaimSearchCompare(): void {
    if (
      this.userAccessLevel === UserAccessLevel.LEVEL_D ||
      this.userAccessLevel === UserAccessLevel.LEVEL_E ||
      this.userAccessLevel === UserAccessLevel.LEVEL_C
    ) {
      this.loader.showLoader();
      this.loadingClaims = true;
      this.router.navigateByUrl(
        `/management/online-eligibility/claim-search-compare?claim1Id=${this.selectedClaims[0].claimId}&claim2Id=${this.selectedClaims[1].claimId}&authNum1=${this.selectedClaims[0].authNum}&authNum2=${this.selectedClaims[1].authNum}&patientId=${this.selectedClaims[0].patientId}&clientId=${this.memberDetails.clientId}&groupNumber=${this.memberDetails.coverage.groupNumber}`
      );
    }
  }

  public performSearch(pageIndex: number, pagePointer: string): void {
    // Add code here..
    if ((this.previousPage == undefined && this.nextPage == undefined) || (this.previousPage < this.nextPage) || this.previousPage == 0) {
      this.pagePointer = 'N';
      this.index = pageIndex;
    } else {
      this.pagePointer = 'P';
      if (this.previousPage != undefined && (this.perPage >= pageIndex)) {
        this.index = 0;
      } else {
        this.index = pageIndex - this.total;
      }
    }
    if (!this.claimSearchFormGroup.value.formDateControl || !this.claimSearchFormGroup.value.formDateControl) return;

    this.eligibilityService.claimSearchCriteria.next({
      fromDate: formatDate(
        new Date(this.claimSearchFormGroup.value.formDateControl)
      ),
      toDate: formatDate(
        new Date(this.claimSearchFormGroup.value.toDateControl)
      ),
      clientId: this.memberDetails?.clientId,
      patientId: this.memberDetails?.patientId,
      cardId: this.memberDetails?.cardId,
      personCode: this.memberDetails?.personCode,
      claimStatus: this.claimSearchFormGroup.value.claimStatus,
      perPage: this.paginator?.pageSize ? this.paginator.pageSize : 10,
      page: this.currentPageIndex + 1,
      migrationFlag: this.migratedFlag,
      total: this.total,
      index: this.index,
      pagePointer: this.pagePointer,
      search: {
        rxNumber: this.claimSearchFormGroup.value.rxNumber,
        drugName: this.claimSearchFormGroup.value.drugName,
        pharmacyName: this.claimSearchFormGroup.value.pharmacy,
      }
    });
    this.appInsightService.trackPageView(AppCenterAnalytics.GET_CLAIM_SEARCH_REQUEST, { data: this.eligibilityService.claimSearchCriteria.value });
  }

  public dateDifferenceValidator(): null | { dateDifference: true } {
    const startDate = this.claimSearchFormGroup.value.formDateControl;
    const endDate = this.claimSearchFormGroup.value.toDateControl;

    if (startDate && endDate) {
      const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
      const differenceInYears =
        differenceInMilliseconds / (1000 * 60 * 60 * 24 * 365);

      if (differenceInYears > 2) {
        return { dateDifference: true };
      }
    }
    return null;
  }

  public selectRow(claim: ClaimSearchResponse): void {
    const index = this.selectedClaims.indexOf(claim);
    if (index >= 0) {
      // If the claim is already selected, unselect it
      claim.selected = false;
      this.selectedClaims.splice(index, 1);
    } else {
      // If the claim is not selected, check if the maximum selection limit is reached
      if (this.selectedClaims.length < 2) {
        claim.selected = true;
        this.selectedClaims.push(claim);
      } else {
        claim.selected = false;
      }
    }
  }

  public getClaimList(): Observable<ClaimSearchListResponse> {
    return this.eligibilityService.searchClaims();
  }

  public isShowClaimStatusSelection() {
    return this.userAccessLevel === UserAccessLevel.LEVEL_D ||
      this.userAccessLevel === UserAccessLevel.LEVEL_E ||
      this.userAccessLevel === UserAccessLevel.LEVEL_C;
  }

  public onPageChange(pageEvent: PageEvent) {
    if (this.prevPerPage && this.prevPerPage !== pageEvent.pageSize) {
      this.prevPerPage = pageEvent.pageSize;
      this.resetSearch();
    } else {
      this.prevPerPage = pageEvent.pageSize;
      this.perPage = pageEvent.pageSize;
      this.previousPage = pageEvent.previousPageIndex;
      this.nextPage = pageEvent.pageIndex;
      this.paginator.pageIndex = this.currentPageIndex = pageEvent.pageIndex;
      this.performSearch(this.index, this.pagePointer);
    }
  }

  public resetSearch() {
    this.currentPageIndex = this.paginator.pageIndex = 0;
    this.index = 0;
    this.total = 0;
    this.previousPage = undefined;
    this.nextPage = undefined;
    this.pagePointer = 'N';
    this.performSearch(this.index, this.pagePointer);
  }

  public requestClaimsData() {
    this.appInsightsService.trackPageView(AppCenterAnalytics.REQUEST_HISTORIC_DATA_BUTTON_CLICKED);
    const dialogRef: MatDialogRef<RequestClaimsDataDialogComponent> =
      this.matDialog.open(RequestClaimsDataDialogComponent, {
        autoFocus: false,
        minWidth: 450,
        data: {
          clientEffectiveDate: this.clientEffectiveDate,
          memberDetails: this.memberDetails
        },
      });
    dialogRef.afterClosed().subscribe((res) => {
      if (res.success) {
        this.openStatusDialog(res.result);
      }
    });
  }

  public openStatusDialog(res): void {
    const dialogRef: MatDialogRef<ErrorComponent> = this.matDialog.open(
      ErrorComponent,
      {
        width: '300px',
        data: {
          title: 'Success',
          message: res.Message ? res.Message : 'Data has been requested successfully',
        },
      }
    );
  }

  ngOnDestroy(): void {
    this.eligibilityService.claimSearchInputs.fromDate =
      this.claimSearchFormGroup.value.formDateControl;
    this.eligibilityService.claimSearchInputs.toDate =
      this.claimSearchFormGroup.value.toDateControl;
    this.eligibilityService.claimSearchInputs.claimStatus =
      this.claimSearchFormGroup.value.claimStatus;
    this.eligibilityService.claimSearchInputs.rxNumber =
      this.claimSearchFormGroup.value.rxNumber;
    this.eligibilityService.claimSearchInputs.drugName =
      this.claimSearchFormGroup.value.drugName;
    this.eligibilityService.claimSearchInputs.pharmacyName =
      this.claimSearchFormGroup.value.pharmacy;
  }
}
